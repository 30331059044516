<template>
  <validation-form ref="settingsLimits"
                   v-slot="{ meta, isSubmitting }"
                   :validation-schema="validationSchema"
                   data-cy="account-settings.temporary-limit.form"
                   class="account-settings-limit"
                   @submit="onSubmit">
    <core-alert v-if="hasBankValidationRequest"
                :icon-name="ECoreIcon.BoInfo"
                :theme="ECoreAlertTheme.Info"
                :text="t('alert.account_temporary_limits_bvr')">
      <template #actions>
        <router-link class="core-link--theme-info core-link--underline core-link--bold"
                     data-cy="card-detail.information.bvr.action"
                     :to="{ name: 'to-validate', query: { mode: 'bank' } }">
          {{ t('action.see_details') }}
        </router-link>
      </template>
    </core-alert>
    <details-block :header-size="EDefaultSize.Large"
                   :loading="loading"
                   :title="t('account.setting.temporary_limit.title')"
                   :columns="1">
      <template #col-1>
        <form-line :title="t('account.setting.temporary_limit.activate')">
          <template #input>
            <validation-field v-slot="field"
                              v-model:model-value="temporarySettingsLimits.enabled"
                              name="transaction_amount_temporary_enabled">
              <core-toggle-switch v-bind="field"
                                  data-cy="account-settings.temporary-limit.enabled.toggle" />
            </validation-field>
          </template>
        </form-line>
        <form-line :title="t('account.setting.temporary_limit.monthly_limit')">
          <template #input>
            <input-number v-model="temporarySettingsLimits.limit"
                          name="transaction_amount_temporary_limit"
                          data-cy="account-settings.temporary-limit.monthly-limit.input"
                          local-string
                          :placeholder="t('placeholder.amount')"
                          :size="ECoreInputSize.Small">
              <template #append>
                <span class="account-settings-limit__currency">€</span>
              </template>
            </input-number>
          </template>
        </form-line>
        <form-line :title="t('account.setting.temporary_limit.activation_period')">
          <template #input>
            <core-date-range-picker :from="computedFromDate"
                                    :to="computedToDate"
                                    :from-placeholder="t('placeholder.date_format')"
                                    :to-placeholder="t('placeholder.date_format')"
                                    :locale="locale"
                                    :size="ECoreInputSize.Small"
                                    :disabled-days="{ from: new Date() }"
                                    name="transaction_amount_temporary_period"
                                    monday-first
                                    data-cy="account-settings.temporary-limit.period.date-range-picker"
                                    @update:from="(date) => temporarySettingsLimits.start_date = date ? dayjs(date).format('YYYY-MM-DD') : null"
                                    @update:to="(date) => temporarySettingsLimits.end_date = date ? dayjs(date).format('YYYY-MM-DD') : null" />
            <validation-field v-model:modelValue="temporarySettingsLimits.start_date"
                              name="transaction_amount_temporary_start_date" />
            <validation-field v-model:modelValue="temporarySettingsLimits.end_date"
                              name="transaction_amount_temporary_end_date" />
          </template>
        </form-line>
      </template>
      <template #footer-right>
        <core-button :text="t('action.cancel')"
                     :theme="ECoreButtonTheme.Transparent"
                     class="mr-1"
                     @click="cancelForm" />
        <core-button :text="t('action.validate')"
                     data-cy="account-settings.temporary-limit.submit"
                     type="submit"
                     :disabled="!meta.valid || isSubmitting"
                     :theme="ECoreButtonTheme.Primary"
                     :loading="isSubmitting" />
      </template>
    </details-block>
  </validation-form>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import {
  CoreAlert,
  CoreButton,
  CoreDateRangePicker,
  CoreToggleSwitch,
  ECoreAlertTheme,
  ECoreButtonTheme,
  ECoreIcon,
  ECoreInputSize
} from '@common/core-ui'
import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import * as yup from 'yup'

import bus from '@/helpers/eventBus'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { IAccountDetails, IAccountTemporaryLimit } from '@/types/account.d'
import { EBankValidationRequestType } from '@/types/bank-validation-request.d'
import { EBankValidationRequestEvent } from '@/types/eventBus.d'
import { EResponseHeader } from '@/types/headers.d'
import { EDefaultSize } from '@/types/size.d'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import FormLine from '@/components/form/FormLine.vue'
import ValidationField from '@/components/form/ValidationField.vue'
import InputNumber from '@/components/inputs/InputNumber.vue'
import ModalOverwriteRequest from '@/components/modal/ModalOverwriteRequest.vue'

const router = useRouter()
const accountStore = useAccountStore()
const appStore = useAppStore()
const { t, locale } = useI18n()

const loading = ref(true)
const responseHeaders = ref<RawAxiosResponseHeaders | AxiosResponseHeaders | null>(null)
const temporaryLimitEnabledInitial = ref(false)

const props = defineProps<{
  accountUuid: string
  accountDetails: IAccountDetails
}>()

const temporarySettingsLimits = ref<IAccountTemporaryLimit>({
  enabled: false,
  end_date: null,
  limit: null,
  start_date: null
})

onMounted(async () => {
  const { data, headers } = await accountStore.getTemporaryLimitSettings(props.accountUuid)
  responseHeaders.value = headers
  if (data) {
    temporarySettingsLimits.value = cloneDeep(data)
    temporaryLimitEnabledInitial.value = temporarySettingsLimits.value.enabled
  }
  bus.on(EBankValidationRequestEvent.Overwrite, saveTemporaryLimitSettings)
  loading.value = false
})

onUnmounted(() => {
  bus.off(EBankValidationRequestEvent.Overwrite, saveTemporaryLimitSettings)
})

const validationSchema = computed(() => {
  return yup.object({
    transaction_amount_temporary_enabled: yup.boolean().when([], {
      is: () => !temporaryLimitEnabledInitial.value,
      then: () => yup.boolean().oneOf([true])
    }),
    transaction_amount_temporary_end_date: temporarySettingsLimits.value.limit
      ? yup.string().required()
      : yup.string().nullable(),
    transaction_amount_temporary_limit: yup.number().required(),
    transaction_amount_temporary_start_date: temporarySettingsLimits.value.limit
      ? yup.string().required()
      : yup.string().nullable()
  })
})

const hasBankValidationRequest = computed(() => {
  return !!responseHeaders.value?.[EResponseHeader.BankValidationRequest]
})

const computedFromDate = computed(() => {
  return temporarySettingsLimits.value.start_date ? new Date(temporarySettingsLimits.value.start_date) : null
})

const computedToDate = computed(() => {
  return temporarySettingsLimits.value.end_date ? new Date(temporarySettingsLimits.value.end_date) : null
})

function cancelForm () {
  router.go(-1)
}

async function onSubmit () {
  if (hasBankValidationRequest.value) {
    appStore.showModal(ModalOverwriteRequest, { requestType: EBankValidationRequestType.UpdateAccountTemporaryLimit })
  } else {
    await saveTemporaryLimitSettings()
  }
}

async function saveTemporaryLimitSettings () {
  const payload = {
    transaction_amount_temporary_enabled: temporarySettingsLimits.value.enabled,
    transaction_amount_temporary_limit: temporarySettingsLimits.value.limit,
    transaction_amount_temporary_start_date: temporarySettingsLimits.value.start_date,
    transaction_amount_temporary_end_date: temporarySettingsLimits.value.end_date
  }

  const { data, headers } = await accountStore.updateTemporaryLimitSettings(props.accountUuid, payload)
  responseHeaders.value = headers
  if (data) {
    temporarySettingsLimits.value = cloneDeep(data)
    temporaryLimitEnabledInitial.value = temporarySettingsLimits.value.enabled
  }
}
</script>

<style scoped lang="stylus">
.account-settings-limit
  display flex
  flex-direction column
  gap 2rem

  &__input-addon
    display flex
    align-items center
    gap .8rem

    span
      font-size 1.55rem
      color $color-gray-600

  &__currency
    color $color-gray-600
</style>
