<template>
  <div class="operations"
       data-cy="operations.view">
    <core-table v-model:sort="sort"
                v-model:pagination="pagination"
                v-model:per-page="perPage"
                :per-page-list="config.results.perPageList"
                data-cy="operations.table"
                show-total-count
                :table-head="tableHead"
                :table-data="operationsData"
                with-filter
                with-search-filter
                :search-placeholder="t('placeholder.search')"
                :loading="loading"
                :route="route"
                :placeholder-text="t('placeholder.no_operation_found')"
                :current-route="currentRoute"
                top-pagination
                :locale="locale"
                @update:per-page="getOperationsData(true)"
                @search-filter="(search) => { filters.search = search; getOperationsData(true) }"
                @row-click="(operation) => { showOperationDetails(operation) }"
                @update:sort="getOperationsData(true)"
                @update:pagination="getOperationsData()">
      <template #head-left>
        <span class="title--sm">
          {{ t('general.operations_list') }}
        </span>
      </template>
      <template #filter-list>
        <operations-filters v-model:filters="filters"
                            :loading="loading"
                            :result-count="pagination.totalItems"
                            @update:filters="getOperationsData(true)" />
      </template>
      <template #created_at="{ date }">
        <span class="operations__date">{{ formatDate(date) }}</span>
      </template>
      <template #label="{ label }">
        <span class="operations__label">{{ label }}</span>
      </template>
      <template #account="{ account }">
        <span class="operations__user">{{ account ? account : '-' }}</span>
      </template>
      <template #user="{ user }">
        <span class="operations__user">{{ user ? user : '-' }}</span>
      </template>
      <template #type="{ category }">
        <span class="operations__category">{{ category }}</span>
      </template>
      <template #amount="{ amount, currency }">
        <span class="operations__amount"
              :class="{ credit: amount > 0 }">
          {{ formatAmount(amount, currency, { signDisplay: 'exceptZero' }) }}
        </span>
      </template>
      <template #status="{ status }">
        <core-badge :theme="getBadge(status, EBadgeResource.Operations)?.theme"
                    :value="getBadge(status, EBadgeResource.Operations)?.label || ''"
                    :fill="ECoreBadgeFill.Shaded" />
      </template>
    </core-table>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { CoreBadge, CoreTable, ECoreBadgeFill, ITableSort, SortOrder } from '@common/core-ui'

import config from '@/config'
import { EBadgeResource, getBadge } from '@/helpers/badge'
import { defaultPagination, parsePagination } from '@/helpers/pagination'
import { formatDate } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { useOperationStore } from '@/stores/operation'
import { IOperation, IOperationData, IOperationParams } from '@/types/operation.d'

import OperationDetails from '@/pages/operations/OperationDetails.vue'

import OperationsFilters from './OperationsFilters.vue'

const { t, locale } = useI18n()
const operationStore = useOperationStore()
const currentRoute = useRoute()
const router = useRouter()
const appStore = useAppStore()

const operationsData: Ref<IOperationData[]> = ref([])

const props = defineProps<{
  page: number,
  accountUuid?: string,
  cardUuid?: string,
  route: string
}>()

const loading = ref(false)

const pagination = ref(defaultPagination)
const perPage = ref<number>(config.results.perPage)

const filters = ref<IOperationParams>({
  created_at_from: undefined,
  created_at_to: undefined,
  amount_min: undefined,
  amount_max: undefined,
  account_uuid: undefined,
  card_uuid: undefined,
  categories: [],
  status: [],
  search: undefined
})

const sort = ref<ITableSort>({
  id: null,
  order: null
})

onMounted(async () => {
  await getOperationsData()
})

watch(() => props.page, () => getOperationsData())

const tableHead = computed(() => {
  return [
    { id: 'created_at', label: t('general.date'), sortable: true, width: 10 },
    { id: 'label', label: t('general.label'), sortable: true, ellipsis: true, width: 30 },
    { id: 'account', label: t('general.customer_account', 1), sortable: false, width: 30, isVisible: !props.accountUuid },
    { id: 'user', label: t('general.user'), sortable: false, width: 20, isVisible: !!props.accountUuid },
    { id: 'type', label: t('general.type'), sortable: true, width: 10 },
    { id: 'amount', label: t('general.amount'), align: 'right', sortable: true, width: 10 },
    { id: 'status', label: t('general.status'), align: 'right', sortable: true, width: 10 }
  ].filter(item => item.isVisible !== false)
})

const sortParam = computed(() => {
  if (!sort.value.id) {
    return null
  }

  return sort.value.order === SortOrder.Desc ? `-${sort.value.id}` : sort.value.id
})

async function getOperationsData (reset = false) {
  loading.value = true

  filters.value.account_uuid = props.accountUuid ?? undefined
  filters.value.card_uuid = props.cardUuid ?? undefined
  const params = {
    ...filters.value,
    page: reset ? 1 : props.page,
    sort: sortParam.value,
    'per-page': perPage.value
  }

  const { data, headers, error } = await operationStore.getOperations(params)
  if (!error) {
    pagination.value = parsePagination(headers)
    operationsData.value = formatOperations(data)

    await router.push({ query: { ...router.currentRoute.value.query, page: pagination.value.current === 1 ? undefined : pagination.value.current } })
  }
  loading.value = false
}

function formatOperations (operations: IOperation[]): IOperationData[] {
  return operations.map(operation => {
    return {
      date: operation.created_at,
      label: operation.label,
      account: `${operation.account?.company.name} - ${operation.account?.label}`,
      user: operation.user?.full_name,
      category: operation.category.label,
      amount: operation.amount,
      currency: operation.currency,
      type: operation.category.value,
      status: operation.status,
      uuid: operation.uuid,
      clickable: true
    }
  })
}

function showOperationDetails (operation: IOperationData) {
  appStore.showSidePanel(OperationDetails, { operationUuid: operation.uuid })
}
</script>
