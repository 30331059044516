import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'
import { defineStore } from 'pinia'

import axiosClient from '@/api'
import bus from '@/helpers/eventBus'
import { showToastSuccess } from '@/helpers/utils/notification'
import i18n from '@/locales'
import {
  ECardStatus,
  EVirtualCardType,
  ICard,
  ICardAdvancedSetting,
  ICardAlertSetting,
  ICardAuthorizationCategorySetting,
  ICardAuthorizationRangeSetting,
  ICardControlSetting,
  ICardDetails,
  ICardLimitSetting,
  ICardOrderForm,
  ICardSecuritySetting,
  ICardSmartCardSetting,
  ICardsParams,
  IDeliveryAddress
} from '@/types/card.d'
import { IValueLabel } from '@/types/default.d'
import { ECardEvent } from '@/types/eventBus.d'
import { EResponseHeader } from '@/types/headers.d'

const { t } = i18n.global

export const useCardStore = defineStore('card', {
  actions: {
    async getCards (params: ICardsParams): Promise<{ data: ICard[], headers: RawAxiosResponseHeaders | AxiosResponseHeaders }> {
      try {
        const response = await axiosClient.get('/cards', { params })
        return { data: response.data, headers: response.headers }
      } catch (error) {
        console.error(error)
        return { data: [], headers: {} }
      }
    },

    async getCard (uuid: string): Promise<{ data: ICardDetails | null, headers: RawAxiosResponseHeaders | AxiosResponseHeaders }> {
      try {
        const response = await axiosClient.get(`/cards/${uuid}`)
        return { data: response.data, headers: response.headers }
      } catch (error) {
        console.error(error)
        return { data: null, headers: {} }
      }
    },

    async getStatus (): Promise<{ label: string, value: string }[]> {
      try {
        const { data } = await axiosClient.get('/cards/status')
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async getLockReasons (): Promise<IValueLabel[]> {
      try {
        const response = await axiosClient.get('/cards/lock-reasons')
        return response.data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async getUnlockReasons (): Promise<IValueLabel[]> {
      try {
        const response = await axiosClient.get('/cards/unlock-reasons')
        return response.data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async getBlockReasons (): Promise<IValueLabel[]> {
      try {
        const response = await axiosClient.get('/cards/block-reasons')
        return response.data
      } catch (error) {
        console.error(error)
        return []
      }
    },
    async lockCard (uuid: string, reason: string): Promise<void> {
      try {
        const { data } = await axiosClient.patch(`/cards/${uuid}/lock`, { reason })
        bus.emit(ECardEvent.Lock, data)
        showToastSuccess(t('success.card_lock'))
      } catch (error) {
        console.error(error)
      }
    },

    async unlockCard (uuid: string, reason: string): Promise<void> {
      try {
        const { data, headers } = await axiosClient.patch(`/cards/${uuid}/unlock`, { reason })
        const toastMessage = headers[EResponseHeader.BankValidationRequest] ? t('success.card_unlock_request') : t('success.card_unlock')
        bus.emit(ECardEvent.Unlock, data)
        showToastSuccess(toastMessage)
      } catch (error) {
        console.error(error)
      }
    },

    async blockCard (uuid: string, reason: string, comment: string, isVirtual: boolean): Promise<void> {
      try {
        const { data } = await axiosClient.patch(`/cards/${uuid}/block`, { reason, comment })
        bus.emit(ECardEvent.Block, data)
        showToastSuccess(isVirtual ? t('success.card_delete') : t('success.card_block'))
      } catch (error) {
        console.error(error)
      }
    },

    async getAvailableExpirationDates (card_type: EVirtualCardType): Promise<{ value: number, expiration_date: string }[]> {
      try {
        const response = await axiosClient.get('/cards/available-expiration-dates', { params: { card_type } })
        return response.data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async getDeliveryAddresses (account_uuid: string): Promise<IDeliveryAddress[]> {
      try {
        const response = await axiosClient.get('/cards/delivery-address', { params: { account_uuid } })
        return response.data.delivery_address
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async orderCard (data: ICardOrderForm) {
      try {
        const response = await axiosClient.post('/cards', data)
        const cardMessage = response.data.status === ECardStatus.StatusToValidateByBank
          ? 'success.card_order_request'
          : 'success.card_order'
        showToastSuccess(t(cardMessage))
        return response.data
      } catch (error) {
        console.error(error)
        return { error: true }
      }
    },

    async getCardSecuritySetting (uuid: string): Promise<ICardSecuritySetting | null> {
      try {
        const { data } = await axiosClient.get(`/cards/${uuid}/settings/security`)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async updateCardSecuritySetting (uuid: string, payload: ICardSecuritySetting): Promise<ICardSecuritySetting | null> {
      try {
        const { data } = await axiosClient.put(`/cards/${uuid}/settings/security`, payload)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getCardLimitSetting (uuid: string): Promise<{data: ICardLimitSetting | null, headers: RawAxiosResponseHeaders | AxiosResponseHeaders}> {
      try {
        const { data, headers } = await axiosClient.get(`/cards/${uuid}/settings/limits`)

        return { data, headers }
      } catch (error) {
        console.error(error)
        return { data: null, headers: {} }
      }
    },

    async updateCardLimitSetting (uuid: string, payload: ICardLimitSetting): Promise<{data: ICardLimitSetting | null, headers: RawAxiosResponseHeaders | AxiosResponseHeaders}> {
      try {
        const { data, headers } = await axiosClient.put(`/cards/${uuid}/settings/limits`, payload)
        const toastMessage = headers[EResponseHeader.BankValidationRequest] ? t('success.update_card_limits_request') : t('success.update_card_limits')
        showToastSuccess(toastMessage)
        return { data, headers }
      } catch (error) {
        console.error(error)
        return { data: null, headers: {} }
      }
    },

    async getCardControlSetting (uuid: string): Promise<ICardControlSetting | null> {
      try {
        const { data } = await axiosClient.get(`/cards/${uuid}/settings/control`)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async updateCardControlSetting (uuid: string, payload: ICardControlSetting): Promise<ICardControlSetting | null> {
      try {
        const { data } = await axiosClient.put(`/cards/${uuid}/settings/control`, payload)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getCardAlertSetting (uuid: string): Promise<ICardAlertSetting | null> {
      try {
        const { data } = await axiosClient.get(`/cards/${uuid}/settings/alerts`)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async updateCardAlertSetting (uuid: string, payload: ICardAlertSetting): Promise<ICardAlertSetting | null> {
      try {
        const { data } = await axiosClient.put(`/cards/${uuid}/settings/alerts`, payload)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getCardSmartCardSetting (uuid: string): Promise<ICardSmartCardSetting | null> {
      try {
        const { data } = await axiosClient.get(`/cards/${uuid}/settings/smart_card`)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async updateCardSmartCardSetting (uuid: string, payload: ICardSmartCardSetting, { file = null, picture = null }: { file: File|null, picture: File|null }): Promise<ICardSmartCardSetting | null> {
      try {
        const formDataPayload = new FormData()
        formDataPayload.append('jsonBody', JSON.stringify(payload))
        if (file) formDataPayload.append('file', file)
        if (picture) formDataPayload.append('picture', picture)

        const { data } = await axiosClient.put(`/cards/${uuid}/settings/smart_card`, formDataPayload)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getCardAuthorizationCategorySetting (uuid: string): Promise<ICardAuthorizationCategorySetting | null> {
      try {
        const { data } = await axiosClient.get(`/cards/${uuid}/settings/authorization_categories`)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async updateCardAuthorizationCategorySetting (uuid: string, payload: ICardAuthorizationCategorySetting): Promise<ICardAuthorizationCategorySetting | null> {
      try {
        const { data } = await axiosClient.put(`/cards/${uuid}/settings/authorization_categories`, payload)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getCardAuthorizationRangeSetting (uuid: string): Promise<ICardAuthorizationRangeSetting | null> {
      try {
        const { data } = await axiosClient.get(`/cards/${uuid}/settings/authorization_range`)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async updateCardAuthorizationRangeSetting (uuid: string, payload: ICardAuthorizationRangeSetting): Promise<ICardAuthorizationRangeSetting | null> {
      try {
        const { data } = await axiosClient.put(`/cards/${uuid}/settings/authorization_range`, payload)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getCardAdvancedSetting (uuid: string): Promise<ICardAdvancedSetting | null> {
      try {
        const { data } = await axiosClient.get(`/cards/${uuid}/settings/advanced`)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async updateCardAdvancedSetting (uuid: string, payload: ICardAdvancedSetting): Promise<ICardAdvancedSetting | null> {
      try {
        const { data } = await axiosClient.put(`/cards/${uuid}/settings/advanced`, payload)

        return data
      } catch (error) {
        console.error(error)
        return null
      }
    }
  }
})
