<template>
  <div class="account-settings">
    <component-navigation v-if="hasPermission(EPermission.AccountTransactionAmountMonthlyLimitUpdate, accountDetails)"
                          class="component-navigation"
                          :label="t('account.setting.limit.title')"
                          route-name="account-settings-limits" />
    <component-navigation v-if="hasPermission(EPermission.AccountTransactionAmountTemporaryUpdate, accountDetails)"
                          class="component-navigation"
                          :label="t('account.setting.temporary_limit.title')"
                          route-name="account-settings-temporary-limits" />
    <component-navigation v-if="hasPermission(EPermission.AccountFeeWorldlineWrite, accountDetails) && hasPermission(EPermission.WorldlineFeeRead)"
                          class="component-navigation"
                          :label="t('general.customer_fee')"
                          route-name="account-settings-fee" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import hasPermission from '@/helpers/permissions'
import { IAccount } from '@/types/account.d'
import { EPermission } from '@/types/permission.d'

import ComponentNavigation from '@/pages/accounts/account-id/tabs/settings/components/ComponentNavigation.vue'

defineProps<{
  accountDetails: IAccount
}>()

const { t } = useI18n()
</script>

<style scoped lang="stylus">
.component-navigation
  margin-bottom 2.8rem
</style>
