<template>
  <sidepanel-layout v-if="bankValidationRequestDetails"
                    class="request-details">
    <template #body>
      <sidepanel-header :icon="icon"
                        :label="getBankValidationRequestLabel(bankValidationRequestDetails)"
                        :status="bankValidationRequestDetails.status"
                        :badge-resource="EBadgeResource.BankValidationRequests" />
      <sidepanel-block :title="t('general.request_details')">
        <template #content>
          <sidepanel-line :label="t('general.request_type')"
                          :data="getBankValidationRequestModeLabel(bankValidationRequestDetails)" />
          <sidepanel-line :label="t('general.creation_date')"
                          :data="formatDateTime(bankValidationRequestDetails.created_at || 0) || '-'" />
          <sidepanel-line :label="t('general.initiator')"
                          :data="createdBy.full_name ? createdBy.full_name : createdBy.name"
                          :clickable="getResource('created_by') === 'user'"
                          @click="getResource('created_by') === 'user' && goToUserProfile(createdBy, getResource('created_by'))" />
          <sidepanel-line :label="t('general.customer_account')"
                          :data="`${bankValidationRequestDetails.account.company.name} - ${bankValidationRequestDetails.account.label}`"
                          clickable
                          @click="router.push({ name: 'account-information', params: { uuid: bankValidationRequestDetails?.account.uuid } })" />
          <bank-validation-request-account-limit-create v-if="isAccountLimitCreate(bankValidationRequestDetails)"
                                                        :account-owner="bankValidationRequestDetails.account.company.user.full_name"
                                                        :account-limit-settings-create="bankValidationRequestDetails.details" />

          <template v-if="isValidated">
            <sidepanel-line :label="t('general.validation_date')"
                            :data="formatDateTime(bankValidationRequestDetails.validated_at || 0) || '-'" />
            <sidepanel-line :label="t('general.validated_by_f')"
                            :data="bankValidationRequestDetails.validated_by.full_name"
                            :clickable="false"
                            @click="goToUserProfile(bankValidationRequestDetails.validated_by, 'bo_user')" />
          </template>

          <template v-if="isRejected">
            <sidepanel-line :label="t('general.rejection_date')"
                            :data="formatDateTime(bankValidationRequestDetails.rejected_at || 0) || '-'" />
            <sidepanel-line :label="t('general.rejected_by_f')"
                            :data="bankValidationRequestDetails.rejected_by.full_name"
                            :clickable="false"
                            @click="goToUserProfile(bankValidationRequestDetails.rejected_by, 'bo_user')" />
          </template>

          <template v-if="isCanceled">
            <sidepanel-line :label="t('general.cancellation_date')"
                            :data="formatDateTime(bankValidationRequestDetails.canceled_at || 0) || '-'" />
            <sidepanel-line v-if="canceledBy"
                            :label="t('general.canceled_by_f')"
                            :data="canceledBy.full_name ? canceledBy.full_name : canceledBy.name"
                            :clickable="getResource('canceled_by') === 'user'"
                            @click="getResource('canceled_by') === 'user' && goToUserProfile(canceledBy, getResource('canceled_by'))" />
          </template>

          <bank-validation-request-user-information v-if="isUserInformationVisible(bankValidationRequestDetails)"
                                                    :user="bankValidationRequestDetails.details.user"
                                                    :account="bankValidationRequestDetails.account" />

          <bank-validation-request-card-information v-if="isCardInformationVisible(bankValidationRequestDetails)"
                                                    :card="bankValidationRequestDetails.details.card" />
        </template>
      </sidepanel-block>

      <bank-validation-request-card-limit v-if="isCardLimitVisible(bankValidationRequestDetails)"
                                          :card="bankValidationRequestDetails.details.card" />

      <bank-validation-request-card-limit-update v-if="isCardLimitUpdateVisible(bankValidationRequestDetails)"
                                                 :card="bankValidationRequestDetails.details.card"
                                                 :card-limit-settings-update="bankValidationRequestDetails.details.limits" />

      <bank-validation-request-account-limit-update v-if="isAccountLimitUpdate(bankValidationRequestDetails)"
                                                    :account-limit-settings-update="bankValidationRequestDetails.details.transaction_amount_monthly" />

      <bank-validation-request-account-temporary-limit-update v-if="isAccountTemporaryLimitUpdate(bankValidationRequestDetails)"
                                                              :account-temporary-limit-settings-update="bankValidationRequestDetails.details" />

      <bank-validation-request-reject-reason v-if="isRejected && hasRejectReason"
                                             :comment="bankValidationRequestDetails.comment" />
    </template>
    <template v-if="isRejectionVisible || isValidationVisible || isCancellationVisible"
              #footer>
      <sidepanel-footer>
        <core-button v-if="isCancellationVisible"
                     block
                     :text="t('action.cancel')"
                     :theme="ECoreButtonTheme.GrayHigh"
                     :appearance="ECoreButtonAppearance.Outlined"
                     @click="cancelBankValidationRequest" />
        <core-button v-if="isRejectionVisible"
                     block
                     :text="t('action.reject')"
                     :theme="ECoreButtonTheme.Danger"
                     :appearance="ECoreButtonAppearance.Outlined"
                     @click="rejectBankValidationRequest" />
        <core-button v-if="isValidationVisible"
                     block
                     :text="t('action.validate')"
                     :theme="ECoreButtonTheme.Info"
                     @click="validateBankValidationRequest" />
      </sidepanel-footer>
    </template>
  </sidepanel-layout>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { CoreButton, ECoreButtonAppearance, ECoreButtonTheme, ECoreIconBoList } from '@common/core-ui'

import { EBadgeResource } from '@/helpers/badge'
import bus from '@/helpers/eventBus'
import hasPermission from '@/helpers/permissions'
import { formatDateTime } from '@/helpers/utils/date'
import { useAppStore } from '@/stores/app'
import { useBankValidationRequestStore } from '@/stores/bank-validation-request'
import { IBoUser } from '@/types/auth.d'
import {
  EBankValidationRequestStatus,
  EBankValidationRequestType,
  getBankValidationRequestLabel,
  getBankValidationRequestModeLabel,
  IBankValidationRequestDetails,
  isAccountLimitCreate,
  isAccountLimitUpdate,
  isAccountTemporaryLimitUpdate,
  isCardCreation,
  isCardHolderCreation,
  isCardLimitUpdate,
  isCardUnlock,
  isUserCreation
} from '@/types/bank-validation-request.d'
import { EBankValidationRequestEvent } from '@/types/eventBus.d'
import { EPermission } from '@/types/permission.d'
import { IUser, IUserOAuthService } from '@/types/user.d'

import ModalCancelBankValidationRequest
  from '@/pages/bank-validation-requests/modal/ModalCancelBankValidationRequest.vue'
import ModalRejectBankValidationRequest
  from '@/pages/bank-validation-requests/modal/ModalRejectBankValidationRequest.vue'
import ModalValidateBankValidationRequest
  from '@/pages/bank-validation-requests/modal/ModalValidateBankValidationRequest.vue'
import BankValidationRequestAccountLimitCreate
  from '@/pages/bank-validation-requests/sidepanel-details/BankValidationRequestAccountLimitCreate.vue'
import BankValidationRequestAccountLimitUpdate
  from '@/pages/bank-validation-requests/sidepanel-details/BankValidationRequestAccountLimitUpdate.vue'
import BankValidationRequestAccountTemporaryLimitUpdate
  from '@/pages/bank-validation-requests/sidepanel-details/BankValidationRequestAccountTemporaryLimitUpdate.vue'
import BankValidationRequestCardInformation
  from '@/pages/bank-validation-requests/sidepanel-details/BankValidationRequestCardInformation.vue'
import BankValidationRequestCardLimit
  from '@/pages/bank-validation-requests/sidepanel-details/BankValidationRequestCardLimit.vue'
import BankValidationRequestCardLimitUpdate
  from '@/pages/bank-validation-requests/sidepanel-details/BankValidationRequestCardLimitUpdate.vue'
import BankValidationRequestRejectReason
  from '@/pages/bank-validation-requests/sidepanel-details/BankValidationRequestRejectReason.vue'
import BankValidationRequestUserInformation
  from '@/pages/bank-validation-requests/sidepanel-details/BankValidationRequestUserInformation.vue'

import SidepanelBlock from '@/components/sidepanel/SidepanelBlock.vue'
import SidepanelFooter from '@/components/sidepanel/SidepanelFooter.vue'
import SidepanelHeader from '@/components/sidepanel/SidepanelHeader2.vue'
import SidepanelLayout from '@/components/sidepanel/SidepanelLayout.vue'
import SidepanelLine from '@/components/sidepanel/SidepanelLine.vue'

const appStore = useAppStore()

const props = defineProps<{
  bankValidationRequestUuid: string
}>()

const router = useRouter()
const { t } = useI18n()
const bankValidationRequestStore = useBankValidationRequestStore()
const bankValidationRequestDetails = ref<IBankValidationRequestDetails | null>(null) as Ref<IBankValidationRequestDetails>

onMounted(() => {
  getBankValidationRequestDetails()
  bus.on(EBankValidationRequestEvent.Cancel, getBankValidationRequestDetails)
  bus.on(EBankValidationRequestEvent.Validate, getBankValidationRequestDetails)
  bus.on(EBankValidationRequestEvent.Reject, getBankValidationRequestDetails)
})

onUnmounted(() => {
  bus.off(EBankValidationRequestEvent.Cancel, getBankValidationRequestDetails)
  bus.off(EBankValidationRequestEvent.Validate, getBankValidationRequestDetails)
  bus.off(EBankValidationRequestEvent.Reject, getBankValidationRequestDetails)
})

async function getBankValidationRequestDetails () {
  const { data } = await bankValidationRequestStore.getBankValidationRequestDetails(props.bankValidationRequestUuid)
  if (data) {
    bankValidationRequestDetails.value = data
  }
}

const icon = computed(() => {
  switch (bankValidationRequestDetails.value?.type) {
    case EBankValidationRequestType.CreateCard : return ECoreIconBoList.BoCreditCard
    case EBankValidationRequestType.CreateUser : return ECoreIconBoList.BoUsers
    case EBankValidationRequestType.CreateUserCard : return ECoreIconBoList.BoUsers
    default: return ECoreIconBoList.BoAlertTriangle
  }
})

const createdBy = computed(() => {
  const details = bankValidationRequestDetails.value
  return details?.created_by_o_auth_service || details?.created_by_bo_user || details?.created_by_user
})

const canceledBy = computed(() => {
  const details = bankValidationRequestDetails.value
  return details?.canceled_by_o_auth_service || details?.canceled_by_bo_user || details?.canceled_by_user
})

function isUserInformationVisible (request: IBankValidationRequestDetails | null) {
  return isUserCreation(request) || isCardHolderCreation(request)
}

function isCardInformationVisible (request: IBankValidationRequestDetails | null) {
  return isCardCreation(request) || isCardUnlock(request) || isCardLimitUpdate(request) || isCardHolderCreation(request)
}

function isCardLimitVisible (request: IBankValidationRequestDetails | null) {
  return isCardCreation(request) || isCardHolderCreation(request)
}

function isCardLimitUpdateVisible (request: IBankValidationRequestDetails | null) {
  return isCardLimitUpdate(request)
}

const isValidationVisible = computed(() => {
  return bankValidationRequestDetails.value &&
   hasPermission(EPermission.BankValidationRequestValidate, bankValidationRequestDetails.value)
})
const isRejectionVisible = computed(() => {
  return bankValidationRequestDetails.value &&
   hasPermission(EPermission.BankValidationRequestReject, bankValidationRequestDetails.value)
})
const isCancellationVisible = computed(() => {
  return bankValidationRequestDetails.value &&
   hasPermission(EPermission.BankValidationRequestCancel, bankValidationRequestDetails.value)
})

const hasRejectReason = computed(() => !!bankValidationRequestDetails.value?.comment)

function getResource (key : string): 'user' | 'bo_user' | 'o_auth' | null {
  const details = bankValidationRequestDetails.value
  const resources = ['bo_user', 'user', 'o_auth']
  for (const resource of resources) {
    if (details[`${key}_${resource}` as keyof IBankValidationRequestDetails]) {
      return resource
    }
  }

  return null
}

const isValidated = computed(() => bankValidationRequestDetails.value?.status === EBankValidationRequestStatus.Validated)
const isRejected = computed(() => bankValidationRequestDetails.value?.status === EBankValidationRequestStatus.Rejected)
const isCanceled = computed(() => bankValidationRequestDetails.value?.status === EBankValidationRequestStatus.Canceled)

async function goToUserProfile (user: IUser | IBoUser | IUserOAuthService, key: 'user' | 'bo_user' | 'o_auth' | null) {
  if (!bankValidationRequestDetails.value || !key) return

  if (key === 'user') {
    return await router.push({ name: 'user-information', params: { uuid: user.uuid }, query: { account_uuid: bankValidationRequestDetails.value.account.uuid } })
  }

  if (key === 'bo_user') {
    // TODO
    // return await router.push({ name: 'bo-user-information', { uuid: user.uuid }})
  }
}

function cancelBankValidationRequest () {
  appStore.showModal(ModalCancelBankValidationRequest, { bankValidationRequest: bankValidationRequestDetails.value })
}

function rejectBankValidationRequest () {
  appStore.showModal(ModalRejectBankValidationRequest, { bankValidationRequest: bankValidationRequestDetails.value })
}

function validateBankValidationRequest () {
  appStore.showModal(ModalValidateBankValidationRequest, { bankValidationRequest: bankValidationRequestDetails.value })
}
</script>

<style lang="stylus" scoped>
.request-details:deep(.sidepanel-footer) > .core-button:not(:last-child)
  margin-right 2rem
</style>
