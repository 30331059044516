<template>
  <sidepanel-line :label="t('general.account_owner')"
                  :data="accountOwner" />
  <sidepanel-line :label="t('general.default_limit')"
                  :data="formatAmount(accountLimitSettingsCreate.transaction_amount_monthly_limit, currency)" />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { formatAmount } from '@/helpers/utils/number'

import SidepanelLine from '@/components/sidepanel/SidepanelLine.vue'

const currency = { iso: 'EUR', label: 'Euro', fraction_digits: 2, flag_url: 'none' }

type TAccountLimitSettingsCreate = {
  transaction_amount_monthly_limit: number
}

const { t } = useI18n()

defineProps<{
  accountOwner: string
  accountLimitSettingsCreate: TAccountLimitSettingsCreate
}>()
</script>
