<template>
  <section-header :title="t('general.customer_account', 2)">
    <template v-if="hasPermission(EPermission.AccountCreate)"
              #actions>
      <router-link data-cy="account.create"
                   :to="{ name: 'account-create' }">
        <core-button :theme="ECoreButtonTheme.Primary"
                     :text="t('action.create_customer')"
                     :prepend-icon="ECoreIconList.Plus" />
      </router-link>
    </template>
  </section-header>
  <section-body>
    <template #body>
      <div class="accounts"
           data-cy="accounts.view">
        <core-table v-model:sort="sort"
                    v-model:pagination="pagination"
                    v-model:per-page="perPage"
                    :per-page-list="config.results.perPageList"
                    data-cy="accounts.table"
                    show-total-count
                    :table-head="tableHead"
                    :table-data="accountsData"
                    with-filter
                    :loading="loading"
                    route="accounts"
                    :placeholder-text="t('placeholder.no_client_found')"
                    :current-route="currentRoute"
                    top-pagination
                    :locale="locale"
                    @update:per-page="getAccountsData(true)"
                    @row-click="(account) => router.push({ name: 'account-information', params: { uuid: account.uuid as string } })"
                    @update:sort="getAccountsData(true)"
                    @update:pagination="getAccountsData()">
          <template #head-left>
            <span class="title--sm">
              {{ t('general.clients_list') }}
            </span>
          </template>
          <template #head-right>
            <core-toggle-switch v-model="displayClosedAccounts"
                                data-cy="accounts.display_closed_accounts.toggle"
                                :label="t('general.display_closed_accounts')"
                                @update:model-value="getAccountsData(true)" />
          </template>
          <template #filter-list>
            <account-filters v-model:filters="filters"
                             :loading="loading"
                             :result-count="pagination.totalItems"
                             @update:filters="getAccountsData(true)" />
          </template>
          <template #department="{ department }">
            <core-icon v-tooltip="{ content: department, theme: 'tooltip' }"
                       :size="20"
                       :theme="ECoreIconTheme.Black"
                       :name="(department === 'Corporate' || !department) ? ECoreIconBoList.BoBuilding : ECoreIconBoList.BoUser" />
          </template>
          <template #client="{ name, external_ref }">
            <div class="accounts__client">
              <span>{{ name }}</span>
              <span>{{ external_ref }}</span>
            </div>
          </template>
          <template #email="{ email }">
            <span class="accounts__email">{{ email ? email : '-' }}</span>
          </template>
          <template v-if="canReadTransactionLimit"
                    #transaction_amount_monthly_limit="{ settings }">
            <div class="accounts__transaction-amount">
              <span v-if="settings.transaction_amount_monthly_consumed != null"
                    :class="{ exceeded: settings.transaction_amount_monthly_consumed > settings.transaction_amount_monthly_limit }">
                {{ formatAmount(settings.transaction_amount_monthly_consumed, currency, { signDisplay: 'never' }) }}
              </span>
              <span v-else>-</span>
              <span v-if="settings.transaction_amount_monthly_limit"> / {{ formatAmount(settings.transaction_amount_monthly_limit, currency, { signDisplay: 'never' }) }}</span>
            </div>
          </template>
          <template #status="{ status }">
            <core-badge :theme="getBadge(status, EBadgeResource.Accounts)?.theme"
                        :value="getBadge(status, EBadgeResource.Accounts)?.label || ''"
                        :fill="ECoreBadgeFill.Shaded" />
          </template>
          <template #action="{ clickable }">
            <core-icon v-if="clickable"
                       :name="ECoreIconBoList.BoChevronRight"
                       :size="16" />
          </template>
        </core-table>
      </div>
    </template>
  </section-body>
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import {
  CoreBadge,
  CoreButton,
  CoreIcon,
  CoreTable,
  CoreToggleSwitch,
  ECoreBadgeFill,
  ECoreButtonTheme,
  ECoreIconBoList,
  ECoreIconList,
  ECoreIconTheme,
  ITableSort,
  SortOrder
} from '@common/core-ui'

import config from '@/config'
import { EBadgeResource, getBadge } from '@/helpers/badge'
import { defaultPagination, parsePagination } from '@/helpers/pagination'
import hasPermission from '@/helpers/permissions'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { EAccountStatus, IAccount, IAccountData } from '@/types/account.d'
import { EPermission } from '@/types/permission.d'

import AccountFilters from '@/pages/accounts/list/AccountsFilters.vue'

import SectionBody from '@/components/section/SectionBody.vue'
import SectionHeader from '@/components/section/SectionHeader.vue'

const { t, locale } = useI18n()
const accountStore = useAccountStore()
const currentRoute = useRoute()
const router = useRouter()

const accountsData: Ref<IAccountData[]> = ref([])

const props = defineProps<{
  page: number,
  search?: string
}>()

const currency = {
  iso: 'EUR',
  label: 'Euro',
  fraction_digits: 2,
  flag_url: 'none'
}

const loading = ref(false)

const pagination = ref(defaultPagination)
const perPage = ref<number>(config.results.perPage)

const filters = ref({
  departments: [],
  status: [],
  transaction_monthly_limit: undefined
})

const sort = ref<ITableSort>({
  id: null,
  order: null
})

const displayClosedAccounts = ref(true)

onMounted(async () => {
  await getAccountsData()
})

watch(() => props.page, () => getAccountsData())
watch(() => props.search, () => getAccountsData(true))

const sortParam = computed(() => {
  if (!sort.value.id) {
    return null
  }

  const sortKey = formatSort(sort.value.id)
  return sort.value.order === SortOrder.Desc ? `-${sortKey}` : sortKey
})

const canReadTransactionLimit = computed(() => {
  return hasPermission(EPermission.AccountTransactionAmountMonthlyLimitRead)
})

const tableHead = computed(() => {
  const baseTableHead = [
    {
      id: 'department',
      label: '',
      fitContent: true
    },
    {
      id: 'client',
      label: t('general.client', 1),
      sortable: true,
      width: 30
    },
    {
      id: 'email',
      label: 'email',
      sortable: true,
      ellipsis: true,
      width: 25
    },
    {
      id: 'transaction_amount_monthly_limit',
      label: t('general.transaction_amount_monthly_limit'),
      align: 'right',
      sortable: true,
      width: 20
    },
    {
      id: 'status',
      label: t('general.status'),
      align: 'right',
      sortable: true,
      width: 15
    },
    {
      id: 'action',
      fitContent: true
    }
  ]

  if (!canReadTransactionLimit.value) {
    return baseTableHead.filter(item => item.id !== 'transaction_amount_monthly_limit')
  }

  return baseTableHead
})

async function getAccountsData (reset = false) {
  loading.value = true

  const params = {
    ...filters.value,
    exclude_status: displayClosedAccounts.value ? [] : [EAccountStatus.Closed],
    page: reset ? 1 : props.page,
    search: props.search,
    sort: sortParam.value,
    'per-page': perPage.value
  }

  const { data, headers, error } = await accountStore.getAccounts(params)
  if (!error) {
    pagination.value = parsePagination(headers)
    accountsData.value = formatAccounts(data)

    await router.push({ query: { ...router.currentRoute.value.query, page: pagination.value.current } })
  }
  loading.value = false
}

function formatAccounts (accounts: IAccount[]) {
  return accounts.map(account => {
    return {
      uuid: account.uuid,
      name: `${account.company.name} - ${account.label}`,
      external_ref: account.external_ref,
      email: account.company.user?.email,
      settings: account.settings,
      status: account.status,
      clickable: true,
      department: account.company.department?.label
    }
  })
}

function formatSort (key: string) {
  switch (key) {
    case 'client':
      return 'company.name'
    case 'email':
      return 'company.user.email'
    case 'transaction_amount_monthly_limit':
      return 'settings.transaction_amount_monthly_consumed'
    default:
      return key
  }
}
</script>
