export enum EPermission {
  AccountCardCreate = 'account.card.create',
  AccountCardRead = 'account.card.read',
  AccountCreate = 'account.create',
  AccountFeeWorldlineWrite = 'account.fee.worldline.write',
  AccountRead = 'account.read',
  AccountTransactionAmountMonthlyExcessAllowedRead = 'account.transaction_amount_monthly_excess_allowed.read',
  AccountTransactionAmountMonthlyExcessAllowedUpdate = 'account.transaction_amount_monthly_excess_allowed.update',
  AccountTransactionAmountMonthlyLimitRead = 'account.transaction_amount_monthly_limit.read',
  AccountTransactionAmountMonthlyLimitUpdate = 'account.transaction_amount_monthly_limit.update',
  AccountTransactionAmountTemporaryRead = 'account.transaction_amount_temporary.read',
  AccountTransactionAmountTemporaryUpdate = 'account.transaction_amount_temporary.update',
  AccountUpdate = 'account.update',
  AccountUserRead = 'account.user.read',
  BankValidationRequestCancel = 'bank_validation_request.cancel',
  BankValidationRequestRead = 'bank_validation_request.read',
  BankValidationRequestReject = 'bank_validation_request.reject',
  BankValidationRequestValidate = 'bank_validation_request.validate',
  BankValidationRequestWrite = 'bank_validation_request.write',
  CardBlock = 'card.block',
  CardLock = 'card.lock',
  CardOperationRead = 'card.operation.read',
  CardPhysicalDeliveryAddressRead = 'card.physical.delivery_address.read',
  CardPhysicalDeliveryAddressWrite = 'card.physical.delivery_address.write',
  CardRead = 'card.read',
  CardSettingAdvancedRead = 'card.setting.advanced.read',
  CardSettingAdvancedUpdate = 'card.setting.advanced.update',
  CardSettingAlertRead = 'card.setting.alert.read',
  CardSettingAlertUpdate = 'card.setting.alert.update',
  CardSettingAuthorizationCategoryRead = 'card.setting.authorization_category.read',
  CardSettingAuthorizationCategoryUpdate = 'card.setting.authorization_category.update',
  CardSettingAuthorizationRangeRead = 'card.setting.authorization_range.read',
  CardSettingAuthorizationRangeUpdate = 'card.setting.authorization_range.update',
  CardSettingControlRead = 'card.setting.control.read',
  CardSettingControlUpdate = 'card.setting.control.update',
  CardSettingLimitRead = 'card.setting.limit.read',
  CardSettingLimitUpdate = 'card.setting.limit.update',
  CardSettingRead = 'card.setting.read',
  CardSettingSecurityRead = 'card.setting.security.read',
  CardSettingSecurityUpdate = 'card.setting.security.update',
  CardSettingSmartCardRead = 'card.setting.smart_card.read',
  CardSettingSmartCardUpdate = 'card.setting.smart_card.update',
  CardUnlock = 'card.unlock',
  DashboardRead = 'dashboard.read',
  DepartmentRead = 'department.read',
  InvitationRead = 'invitation.read',
  InvitationRenew = 'invitation.renew',
  InvitationRightRead = 'invitation.right.read',
  OperationRead = 'operation.read',
  OperationUpdate = 'operation.update',
  ScaTokenRead = 'sca_token.read',
  ScaTokenRevoke = 'sca_token.revoke',
  UserAccountEnrollmentSend = 'user.account_enrollment.send',
  UserIdentityCardRead = 'user.identity_card.read',
  UserPasswordRecoverySend = 'user.password_recovery.send',
  UserRead = 'user.read',
  UserRemoveFullAccess = 'user.remove_full_access',
  UserRightRead = 'user.right.read',
  UserRightWrite = 'user.right.write',
  UserSetFullAccess = 'user.set_full_access',
  UserSetMainOwner = 'user.set_main_owner',
  WorldlineFeeRead = 'worldline.fee.read',
}
