<template>
  <div class="account-users"
       data-cy="account-users.view">
    <core-table v-model:sort="sort"
                v-model:pagination="pagination"
                v-model:per-page="perPage"
                :per-page-list="config.results.perPageList"
                data-cy="account-users.table"
                show-total-count
                :table-head="tableHead"
                :table-data="usersData"
                :loading="loading"
                route="account-users"
                :placeholder-text="t('placeholder.no_user_found')"
                :current-route="currentRoute"
                with-search-filter
                :search-placeholder="t('placeholder.search')"
                top-pagination
                :locale="locale"
                @update:per-page="getUsersData(true)"
                @row-click="data => redirectToUserDetails(data)"
                @search-filter="searchString => search = searchString"
                @update:sort="getUsersData(true)"
                @update:pagination="getUsersData()">
      <template #head-left>
        <span class="account-users__title title--sm">
          {{ t('general.users_list') }}
          <span v-if="!loading && pagination.totalItems"
                class="account-users__count">({{ pagination.totalItems }})</span>
        </span>
        <component-nav-pills :routes="navPillsRoutes" />
      </template>
      <template #icon>
        <core-icon :name="ECoreIconBoList.BoUser" />
      </template>
      <template #name="{ name, email }">
        <span class="account-users__name">{{ name }}</span><br><span class="account-users__email">{{ email }}</span>
      </template>
      <template #access_rights="{ accessRights, isMainOwner }">
        <account-user-access-rights :access-rights="accessRights"
                                    :is-main-owner="isMainOwner" />
      </template>
      <template #cards_count="{ cardsCount }">
        {{ cardsCount }}
      </template>
      <template #status="{ status }">
        <core-badge :theme="getBadge(status, badgeResource)?.theme"
                    :value="getBadge(status, badgeResource)?.label || ''"
                    :fill="ECoreBadgeFill.Shaded" />
      </template>

      <template #action="{ clickable }">
        <core-icon v-if="clickable"
                   :name="ECoreIconBoList.BoChevronRight"
                   :size="16" />
      </template>
    </core-table>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { CoreBadge, CoreIcon, CoreTable, ECoreBadgeFill, ECoreIconBoList, ITableSort, SortOrder } from '@common/core-ui'

import config from '@/config'
import { EBadgeResource, getBadge } from '@/helpers/badge'
import { defaultPagination, parsePagination } from '@/helpers/pagination'
import { useInvitationStore } from '@/stores/invitation'
import { useUsersStore } from '@/stores/user'
import { EInvitationStatus, IInvitation, IInvitationParams } from '@/types/invitation.d'
import { EUserStatus, IUser, IUserData, IUserParams } from '@/types/user.d'

import AccountUserAccessRights from '@/pages/accounts/account-id/tabs/users/AccountUserAccessRights.vue'

import ComponentNavPills from '@/components/ComponentNavPills.vue'

const { t, locale } = useI18n()
const userStore = useUsersStore()
const invitationStore = useInvitationStore()
const currentRoute = useRoute()
const router = useRouter()

const usersData: Ref<IUserData[]> = ref([])

enum EState {
  Pending = 'pending',
  Revoked = 'revoked'
}

const props = defineProps<{
  accountUuid: string,
  page: number,
  state: EState | null
}>()

const navPillsRoutes = computed(() => ([
  { label: t('status.valid', 2) },
  { label: t('status.pending'), query: { state: EState.Pending } },
  { label: t('status.revoked_m', 2), query: { state: EState.Revoked } }
].map(route => ({
  ...route,
  name: currentRoute.name as string,
  isActive: (!props.state && !route.query) || props.state === route.query?.state
}))))

const loading = ref(false)

const pagination = ref(defaultPagination)

const sort = ref<ITableSort>({
  id: null,
  order: null
})

const search = ref('')
const perPage = ref(config.results.perPage)

onMounted(async () => {
  await getUsersData()
})

watch(() => props.page, () => getUsersData())
watch(() => search.value, () => getUsersData(true))
watch(() => props.state, () => getUsersData())

const sortParam = computed(() => {
  if (!sort.value.id) {
    return null
  }

  return sort.value.order === SortOrder.Desc ? `-${sort.value.id}` : sort.value.id
})

const tableHead = computed(() => {
  return [
    {
      id: 'icon',
      label: '',
      align: 'center',
      fitContent: true
    },
    {
      id: 'name',
      label: t('general.user'),
      sortable: true,
      width: 30
    },
    {
      id: 'access_rights',
      label: t('general.access_right', 2),
      sortable: false,
      ellipsis: true,
      width: isInvitationList.value ? 70 : 50
    },
    {
      id: 'cards_count',
      label: t('general.cards_count'),
      align: 'center',
      sortable: false,
      width: 20,
      isVisible: !isInvitationList.value
    },
    {
      id: 'status',
      label: t('general.status'),
      align: 'right',
      sortable: true,
      width: 10
    },
    {
      id: 'action',
      fitContent: true
    }
  ].filter(item => item.isVisible !== false)
})

const isInvitationList = computed(() => {
  return props.state === EState.Pending
})

const badgeResource = computed(() => {
  return isInvitationList.value ? EBadgeResource.Invitations : EBadgeResource.Users
})

const filters = computed(() => {
  if (isInvitationList.value) {
    return {
      status: [EInvitationStatus.Deleted, EInvitationStatus.Expired, EInvitationStatus.Pending, EInvitationStatus.Refused, EInvitationStatus.ToValidate]
    }
  }
  if (props.state === EState.Revoked) { return { status: [EUserStatus.Deleted] } }
  return { status: [EUserStatus.Active] }
})

async function getUsersData (reset = false) {
  loading.value = true

  const params = {
    ...filters.value,
    account_uuid: props.accountUuid,
    page: reset ? 1 : props.page,
    search: search.value,
    sort: sortParam.value,
    'per-page': perPage.value
  }

  const { data, headers, error } = isInvitationList.value
    ? await invitationStore.getInvitations(params as IInvitationParams)
    : await userStore.getUsers(params as IUserParams)

  pagination.value = parsePagination(headers)
  usersData.value = formatUsers(data)
  if (!error) {
    await router.push({ query: { ...router.currentRoute.value.query, page: pagination.value.current } })
  }
  loading.value = false
}

function formatUsers (users: (IUser|IInvitation)[]): (IUserData[]) {
  return users.map(user => {
    return {
      uuid: user.uuid,
      name: user.full_name,
      email: user.email,
      status: user.status,
      cardsCount: getUserCardsCount(user),
      accessRights: user.rights,
      isMainOwner: user.is_main_owner,
      clickable: true
    }
  })
}

function getUserCardsCount (user: IUser | IInvitation): number {
  return user?.counts?.cards?.types
    ? Object.values(user.counts.cards.types)
      .reduce((acc, cur) => (acc as number) + (cur as {total: number}).total, 0) as number
    : 0
}

function redirectToUserDetails (rowData: IUserData) {
  router.push({
    name: isInvitationList.value ? 'invitation-information' : 'user-information',
    params: {
      uuid: rowData.uuid
    },
    query: {
      account_uuid: props.accountUuid
    }
  })
}
</script>

<style lang="stylus" scoped>
.account-users
  &__title
    position relative
    margin-right 4.8rem

  &__count
    position absolute
    left 100%
    margin-left 0.4rem

  &__name
    font-weight 500
    color $color-gray-900
  &__email
    color $color-gray-500
</style>
