<template>
  <id-layout :badges="[accountDetails?.status || '']"
             :badge-resource="EBadgeResource.Accounts"
             go-back-route="accounts"
             :title="accountTitle"
             :content-loaded="!!accountDetails">
    <template #view>
      <router-view :account-details="accountDetails"
                   @update:account-details="updateAccountDetails" />
    </template>
    <template v-if="items.length"
              #actions>
      <core-button-actions :append-icon="ECoreIcon.BoChevronDown"
                           :theme="ECoreButtonTheme.GrayHigh"
                           data-cy="account-detail.button-actions"
                           :text="t('general.action')"
                           :items="items" />
    </template>
  </id-layout>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { CoreButtonActions, ECoreButtonTheme, ECoreIcon } from '@common/core-ui'

import { EBadgeResource } from '@/helpers/badge'
import bus from '@/helpers/eventBus'
import hasPermission from '@/helpers/permissions'
import { useAccountStore } from '@/stores/account'
import { IAccountDetails, IAccountTemporaryLimit } from '@/types/account.d'
import { EAccountEvent } from '@/types/eventBus.d'
import { EPermission } from '@/types/permission.d'

import IdLayout from '@/components/layout/IdLayout.vue'

const accountStore = useAccountStore()
const router = useRouter()
const { t } = useI18n()
const props = defineProps<{
    uuid: string
}>()

const accountDetails = ref<IAccountDetails | null>(null)

onMounted(async () => {
  accountDetails.value = await accountStore.getAccount(props.uuid)
  bus.on(EAccountEvent.UpdateTemporaryLimit, updateTemporaryLimit)
})

onUnmounted(() => {
  bus.off(EAccountEvent.UpdateTemporaryLimit, updateTemporaryLimit)
})

const items = computed(() => {
  if (!accountDetails.value) return []
  return [
    {
      label: t('action.order_card'),
      icon: ECoreIcon.BoCreditCard,
      onClick: () => router.push({ name: 'account-card-order', params: { uuid: props.uuid } }),
      permission: hasPermission(EPermission.AccountCardCreate, accountDetails.value)
    }
  ].filter(item => item.permission)
})

const accountTitle = computed(() => {
  if (!accountDetails.value) return ''
  return `${accountDetails.value?.company.name} - ${accountDetails.value?.label}`
})

function updateAccountDetails (updatedAccountDetails: IAccountDetails) {
  accountDetails.value = updatedAccountDetails
}

function updateTemporaryLimit (temporaryLimit: IAccountTemporaryLimit) {
  accountDetails.value = {
    ...accountDetails.value,
    settings: {
      ...accountDetails.value?.settings,
      transaction_amount_temporary: temporaryLimit
    }
  }
}
</script>
