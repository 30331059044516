import { JWTPayload } from 'jose'

import { EPermission } from '@/types/permission.d'

export enum OAuthClient {
  Bred = 'bred',
  Dev = 'dev',
  Sagacorp = 'sagacorp',
  Sbe = 'sbe',
}

export interface IBoUserLight {
  uuid: string
  email: string
  first_name: string
  last_name: string
  full_name: string
}

export interface IBoUser extends IBoUserLight {
  brand: string
  loggedIn: boolean
  token: string | null
  decodedToken: JWTPayload | null
  permissions: EPermission[]
}

export interface OAuthParams {
  code: string
  state: string
  session_state?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isOfTypeOauthParams (params: any): params is OAuthParams {
  return typeof params.code === 'string' &&
    typeof params.state === 'string' &&
    (typeof params.session_state === 'string' || typeof params.session_state === 'undefined')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isOfTypeOAuthClient (client: any): client is OAuthClient {
  return Object.values(OAuthClient).includes(client)
}
