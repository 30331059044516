import IBAN from 'iban'

export const formatIban = (iban: string) => {
  return iban && IBAN.isValid(iban)
    ? iban.replace(/[^\dA-Z]/g, '')?.match(/(.{1,4})/g)?.join(' ') || iban
    : iban
}

export const formatIbanOnKeyUp = (iban: string) => {
  if (iban.length >= 2 && Object.keys(IBAN.countries).includes(iban.slice(0, 2).toUpperCase())) {
    iban = iban.toUpperCase().replace(/[^\dA-Z]/g, '')?.match(/(.{1,4})/g)?.join(' ') || iban
  }
  return iban
}

export const formatIbanHidden = (iban: string) => {
  if (!iban || !IBAN.isValid(iban)) { return iban }
  const hiddenIban: string = iban.slice(0, 4) + '*'.repeat(iban.length - 8) + iban.slice(-4)
  return hiddenIban.replace(/[^\dA-Z\\*]/g, '')?.match(/(.{1,4})/g)?.join(' ') || iban
}

export const autoCompleteIban = (e:KeyboardEvent) => {
  const target: HTMLInputElement = e.target as HTMLInputElement
  const keys: string[] = [
    'Backspace',
    'ArrowRight',
    'ArrowLeft',
    'MetaLeft',
    'MetaRight',
    'ControlLeft',
    'ControlRight'
  ]
  return keys.includes(e.code) ? target.value : formatIban(target.value)
}
