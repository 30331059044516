<template>
  <core-modal active
              :title="t('modal.sca_revoke.title')"
              :closable-button="false"
              data-cy="sca.modal-action.container">
    <template #body>
      <p class="pre-line">
        {{ t('modal.sca_revoke.content', { content: scaName }) }}
      </p>
    </template>
    <template #footer>
      <core-button :theme="ECoreButtonTheme.Transparent"
                   :appearance="ECoreButtonAppearance.Filled"
                   :text="t('action.cancel')"
                   @click="closeModal" />
      <core-button :text="t('action.delete')"
                   :theme="ECoreButtonTheme.Danger"
                   :loading="loading"
                   :disabled="loading"
                   data-cy="sca.modal-action.button"
                   @click="onSubmit" />
    </template>
  </core-modal>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreButton,
  CoreModal,
  ECoreButtonAppearance,
  ECoreButtonTheme
} from '@common/core-ui'

import bus from '@/helpers/eventBus'
import { useAppStore } from '@/stores/app'
import { useUsersStore } from '@/stores/user'
import { EScaEvent } from '@/types/eventBus.d'

const { t } = useI18n()

const props = defineProps<{
  uuid: string
  userUuid: string
  scaName: string
}>()

const appStore = useAppStore()
const userStore = useUsersStore()

const loading = ref(false)

onMounted(async () => {
  bus.on(EScaEvent.Revoke, closeModal)
})

onUnmounted(() => {
  bus.off(EScaEvent.Revoke, closeModal)
})

function closeModal () {
  appStore.closeModal()
}

async function onSubmit () {
  loading.value = true
  await userStore.deleteUserSca(props.uuid, props.userUuid)
  loading.value = false
}
</script>
