<template>
  <core-modal active
              :size="ECoreModalSize.Small"
              :title="t('modal.resend_invitation.title')"
              :closable-button="false"
              data-cy="account-invitations.detail.modal.renew-invitation">
    <template #body>
      <p class="pre-line">
        {{ t('modal.resend_invitation.content', { email: invitationDetails.email, name: invitationDetails.full_name }) }}
      </p>
    </template>
    <template #footer>
      <core-button :theme="ECoreButtonTheme.Transparent"
                   :appearance="ECoreButtonAppearance.Filled"
                   :text="t('action.cancel')"
                   @click="closeModal" />
      <core-button :text="t('action.confirm')"
                   :loading="loading"
                   :disabled="loading"
                   data-cy="account-invitations.detail.modal.renew-invitation.submit"
                   @click="onSubmit" />
    </template>
  </core-modal>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreButton,
  CoreModal,
  ECoreButtonAppearance,
  ECoreButtonTheme
  , ECoreModalSize
} from '@common/core-ui'

import bus from '@/helpers/eventBus'
import { useAppStore } from '@/stores/app'
import { useInvitationStore } from '@/stores/invitation'
import { EInvitationEvent } from '@/types/eventBus.d'
import { IInvitationDetails } from '@/types/invitation.d'

const { t } = useI18n()

const props = defineProps<{
  invitationDetails: IInvitationDetails
}>()

const appStore = useAppStore()
const invitationStore = useInvitationStore()

const loading = ref(false)

onMounted(async () => {
  bus.on(EInvitationEvent.Resend, closeModal)
})

onUnmounted(() => {
  bus.off(EInvitationEvent.Resend, closeModal)
})

function closeModal () {
  appStore.closeModal()
}

async function onSubmit () {
  loading.value = true
  await invitationStore.resendInvitation(props.invitationDetails.uuid)
  loading.value = false
}
</script>
