<template>
  <core-modal active
              :size="ECoreModalSize.Small"
              :title="t('modal.set_password.title')"
              :closable-button="false"
              data-cy="set_password.modal-action.container">
    <template #body>
      <p class="pre-line">
        {{ t('modal.set_password.content') }}
      </p>
    </template>
    <template #footer>
      <core-button :theme="ECoreButtonTheme.Transparent"
                   :appearance="ECoreButtonAppearance.Filled"
                   :text="t('action.cancel')"
                   @click="closeModal" />
      <core-button :text="t('action.confirm')"
                   :theme="ECoreButtonTheme.Primary"
                   :loading="loading"
                   :disabled="loading"
                   data-cy="set_password.modal-action.button"
                   @click="onSubmit" />
    </template>
  </core-modal>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreButton,
  CoreModal,
  ECoreButtonAppearance,
  ECoreButtonTheme,
  ECoreModalSize
} from '@common/core-ui'

import { useAppStore } from '@/stores/app'
import { useUsersStore } from '@/stores/user'

const { t } = useI18n()

const props = defineProps<{
  userUuid: string,
  accountUuid: string
}>()

const appStore = useAppStore()
const userStore = useUsersStore()

const loading = ref(false)

function closeModal () {
  appStore.closeModal()
}

async function onSubmit () {
  loading.value = true
  const response = await userStore.setPassword(props.userUuid, props.accountUuid)
  if (response) {
    closeModal()
  }
  loading.value = false
}
</script>
