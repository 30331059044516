<template>
  <div class="default-layout">
    <core-sidebar :items="menu"
                  :current-route="route.name"
                  :is-open="appStore.sidebar.active">
      <template #header>
        <div class="sidebar__header">
          <button class="menu-button"
                  @click="appStore.toggleSidebar(false)">
            <core-icon :name="ECoreIconBoList.BoMenu"
                       :size="20" />
          </button>
          <router-link :to="{ name: 'dashboard' }">
            <header-brand-logo class="header-brand-logo" />
          </router-link>
          <div class="sidebar__header__group">
            <span>{{ auth.boUserFullName }}</span>
            <span data-cy="logout"
                  @click.prevent="logout">{{ t('general.disconnect') }}</span>
          </div>
        </div>
      </template>
    </core-sidebar>
    <div class="default-layout__content">
      <component-header />
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { CoreIcon, CoreSidebar, ECoreIconBoList } from '@common/core-ui'

import hasPermission from '@/helpers/permissions'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useBankValidationRequestStore } from '@/stores/bank-validation-request'
import { EPermission } from '@/types/permission.d'

import ComponentHeader from '@/components/layout/ComponentHeader.vue'

import HeaderBrandLogo from '@/assets/img/header-brand-logo.vue'

const bankValidationRequestStore = useBankValidationRequestStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const auth = useAuthStore()
const appStore = useAppStore()

const menu = computed(() => {
  const routes = router.getRoutes()
  const menu = []

  for (const route of routes) {
    if (typeof route.name === 'string' && route.meta.menu && hasPermission(route.meta.permissions || [])) {
      menu.push({
        routeName: route.path,
        icon: route.meta.menu.icon,
        label: getMenuItemName(route.meta.menu.label),
        badge: getBadgeCount(route.name)
      })
    }
  }

  return menu
})

onMounted(() => {
  getCounts()
})

function getMenuItemName (itemName: string): string {
  return t(`menu.${itemName}`)
}

function getBadgeCount (routeName: string) {
  switch (routeName) {
    case 'bank-validation-requests': return bankValidationRequestStore.toValidateCount
    default: return 0
  }
}

function getCounts () {
  if (hasPermission(EPermission.BankValidationRequestRead) && !routeMatch('bank-validation-requests')) {
    bankValidationRequestStore.getBankValidationRequestsCount()
  }
}

function routeMatch (routeName: string): boolean {
  return router.currentRoute.value.matched.some(r => r.name === routeName)
}

async function logout () {
  await auth.logout()
  router.push({ name: 'login' }).catch(() => null)
}
</script>
