<template>
  <div class="card-limit-setting">
    <core-alert v-if="hasBankValidationRequest"
                :icon-name="ECoreIcon.BoInfo"
                :theme="ECoreAlertTheme.Info"
                :text="t('alert.card_limits_bvr')">
      <template #actions>
        <router-link class="core-link--theme-info core-link--underline core-link--bold"
                     data-cy="card-detail.information.bvr.action"
                     :to="{ name: 'to-validate', query: { mode: 'bank' } }">
          {{ t('action.see_details') }}
        </router-link>
      </template>
    </core-alert>
    <details-block data-cy="card.settings.limits"
                   :title="t('menu.limits')"
                   :loading="loading && !limitSettingModel">
      <template v-if="limitSettingModel"
                #body>
        <details-line v-if="limitSettingModel.hasOwnProperty('initial_amount')"
                      class="card-limit-item"
                      height="5.6rem">
          <template #content>
            {{ t('card.setting.initial_amount') }}
          </template>
          <template #param>
            <core-input-number v-model:model-value="limitSettingModel.initial_amount"
                               name="initial_amount"
                               :disabled="isDisabled">
              <template #append>
                {{ getCurrencySymbol(currency) }}
              </template>
            </core-input-number>
          </template>
        </details-line>

        <template v-if="isRecurringVirtualCard">
          <details-line :title="t('general.periodicity')">
            <template #data>
              <core-dropdown :model-value="selectedRecurringVirtualCardPeriodicity"
                             :items="periodicityList"
                             :disabled="isDisabled"
                             @update:model-value="onRecurringVirtualCardPeriodicitySelect" />
            </template>
          </details-line>
          <details-line :title="t('general.ceiling')">
            <template #data>
              <core-input-number :model-value="selectedRecurringVirtualCardLimit"
                                 name="recurring-virtual-card-limit"
                                 :disabled="isDisabled"
                                 @update:model-value="onRecurringVirtualCardLimitUpdate">
                <template #append>
                  {{ getCurrencySymbol(currency) }}
                </template>
              </core-input-number>
            </template>
          </details-line>
        </template>

        <template v-else-if="limitSettingModel.payment || limitSettingModel.withdrawal">
          <div class="card-limit-setting__content">
            <div v-if="limitSettingModel.payment">
              <details-label :title="t(`general.payment`)" />

              <card-limit-item v-if="limitSettingModel.payment_transaction"
                               v-model:model-value="limitSettingModel.payment_transaction"
                               :label="t('general.per_transaction')"
                               :input-placeholder="t('placeholder.no_ceiling')"
                               :disabled="isDisabled">
                <template #input-append>
                  {{ getCurrencySymbol(currency) }}
                </template>
              </card-limit-item>

              <card-limit-item v-for="(item, period) in limitSettingModel.payment"
                               :key="`${period}-payment`"
                               v-model:model-value="limitSettingModel.payment[period]"
                               :label="t(`general.${period}`)"
                               :input-placeholder="t('placeholder.no_ceiling')"
                               :disabled="isDisabled">
                <template #input-append>
                  {{ getCurrencySymbol(currency) }}
                </template>
              </card-limit-item>
            </div>

            <div v-if="limitSettingModel.withdrawal">
              <details-label :title="t(`general.withdrawal`)" />

              <card-limit-item v-if="limitSettingModel.withdrawal_transaction"
                               v-model:model-value="limitSettingModel.withdrawal_transaction"
                               :label="t('general.per_transaction')"
                               :input-placeholder="t('placeholder.no_ceiling')"
                               :disabled="isDisabled">
                <template #input-append>
                  {{ getCurrencySymbol(currency) }}
                </template>
              </card-limit-item>

              <card-limit-item v-for="(item, period) in limitSettingModel.withdrawal"
                               :key="`${period}-withdrawal`"
                               v-model:model-value="limitSettingModel.withdrawal[period]"
                               :label="t(`general.${period}`)"
                               :input-placeholder="t('placeholder.no_ceiling')"
                               :disabled="isDisabled">
                <template #input-append>
                  {{ getCurrencySymbol(currency) }}
                </template>
              </card-limit-item>
            </div>
          </div>
        </template>

        <details-line>
          <template #content>
            <core-toggle-switch v-model:model-value="limitSettingModel.display_limit"
                                :label="t('card.setting.display_limit_for_card_holder')"
                                :disabled="isDisabled" />
          </template>
        </details-line>
      </template>

      <template v-if="isSaveButtonVisible"
                #footer-right>
        <core-button :text="t('action.cancel')"
                     :theme="ECoreButtonTheme.Transparent"
                     class="mr-2"
                     @click="cancel" />
        <core-button :text="t('action.save')"
                     :loading="loading"
                     @click="handleLimitSetting" />
      </template>
    </details-block>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreAlert, CoreButton, CoreDropdown, CoreInputNumber, CoreToggleSwitch, ECoreAlertTheme, ECoreButtonTheme, ECoreIcon } from '@common/core-ui'
import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'
import _ from 'lodash'

import bus from '@/helpers/eventBus'
import hasPermission from '@/helpers/permissions'
import { getCurrencySymbol } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'
import { EBankValidationRequestType } from '@/types/bank-validation-request.d'
import {
  ECardPeriod,
  ICardDetails,
  ICardLimitSetting,
  IPaymentWithdrawalSetting,
  isRecurringVirtualCardType
} from '@/types/card.d'
import { ICurrency } from '@/types/currency.d'
import { EBankValidationRequestEvent } from '@/types/eventBus.d'
import { EResponseHeader } from '@/types/headers.d'
import { EPermission } from '@/types/permission.d'

import CardLimitItem from '@/pages/cards/card-id/tabs/settings/blocks/components/CardLimitItem.vue'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLabel from '@/components/details-block/DetailsLabel.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'
import ModalOverwriteRequest from '@/components/modal/ModalOverwriteRequest.vue'

const cardStore = useCardStore()
const appStore = useAppStore()
const { t } = useI18n()

const props = defineProps<{
  cardUuid: string
  cardDetails: ICardDetails
  currency: ICurrency
}>()

const loading = ref<boolean>(true)
const limitSetting = ref<ICardLimitSetting | null>(null)
const limitSettingModel = ref<ICardLimitSetting | null>(null)
const responseHeaders = ref<RawAxiosResponseHeaders | AxiosResponseHeaders | null>(null)

onMounted(async () => {
  const { data, headers } = await cardStore.getCardLimitSetting(props.cardUuid)
  limitSetting.value = data
  responseHeaders.value = headers
  limitSettingModel.value = _.cloneDeep(limitSetting.value)
  loading.value = false
  bus.on(EBankValidationRequestEvent.Overwrite, saveLimitSetting)
})

onUnmounted(() => {
  bus.off(EBankValidationRequestEvent.Overwrite, saveLimitSetting)
})

const isDisabled = computed(() => {
  return !hasPermission(EPermission.CardSettingLimitUpdate, props.cardDetails)
})

const isRecurringVirtualCard = computed(() => {
  return isRecurringVirtualCardType(props.cardDetails.type)
})

const periodicityList = computed(() => {
  if (!limitSettingModel.value?.payment) return []
  return Object.keys(limitSettingModel.value.payment)
    .map(key => ({ label: t(`general.${key}`), value: key }))
})

const selectedRecurringVirtualCardPeriodicity = computed<ECardPeriod | null>(() => {
  if (!limitSettingModel.value?.payment) return null
  return Object.entries(limitSettingModel.value?.payment).find(([key, value]) => key && value.enabled)?.[0] as ECardPeriod || null
})

const selectedRecurringVirtualCardLimit = computed<number>(() => {
  if (!selectedRecurringVirtualCardPeriodicity.value || !limitSettingModel.value?.payment) return 0
  return limitSettingModel.value?.payment?.[selectedRecurringVirtualCardPeriodicity.value as ECardPeriod].limit
})

const hasBankValidationRequest = computed(() => {
  return !!responseHeaders.value?.[EResponseHeader.BankValidationRequest]
})

function onRecurringVirtualCardPeriodicitySelect (selectedPeriodicity: ECardPeriod) {
  if (!limitSettingModel.value) return
  const newValue = _.cloneDeep(limitSettingModel.value?.payment) as IPaymentWithdrawalSetting
  Object.values(ECardPeriod).forEach((period: ECardPeriod) => {
    if (period === selectedPeriodicity) {
      newValue[period] = { ...newValue[period], enabled: true, limit: selectedRecurringVirtualCardLimit.value }
    } else {
      newValue[period] = { ...newValue[period], enabled: false, limit: 0 }
    }
  })
  limitSettingModel.value.payment = newValue
}

function onRecurringVirtualCardLimitUpdate (inputLimit: number | null) {
  if (!limitSettingModel?.value?.payment || !selectedRecurringVirtualCardPeriodicity.value || inputLimit === null) return
  limitSettingModel.value.payment[selectedRecurringVirtualCardPeriodicity.value].limit = inputLimit
}

const isSaveButtonVisible = computed(() => {
  return !_.isEqual(limitSetting.value, limitSettingModel.value) && !isDisabled.value
})

function cancel () {
  limitSettingModel.value = _.cloneDeep(limitSetting.value)
}

async function handleLimitSetting () {
  if (hasBankValidationRequest.value) {
    appStore.showModal(ModalOverwriteRequest, { requestType: EBankValidationRequestType.UpdateCardLimit })
  } else {
    saveLimitSetting()
  }
}

async function saveLimitSetting () {
  if (!limitSettingModel.value) return
  loading.value = true
  const { data, headers } = await cardStore.updateCardLimitSetting(props.cardUuid, limitSettingModel.value)
  if (data) {
    limitSetting.value = data
    limitSettingModel.value = _.cloneDeep(limitSetting.value)
    responseHeaders.value = headers
  }
  loading.value = false
}
</script>

<style scoped lang="stylus">
.card-limit-setting
  display flex
  flex-direction column
  width 100%
  gap 2rem

  &__content
    vertical-split(2.4rem, true)

  .core-input
    width 1rem
</style>
