<template>
  <core-checkbox v-bind="props"
                 :error="errorMessage"
                 @update:model-value="value => handleChange(value, !!errorMessage)" />
</template>

<script setup lang="ts">
import { CoreCheckbox, ECoreCheckboxTheme, ICheckboxProps } from '@common/core-ui'
import { useField } from 'vee-validate'

const props = withDefaults(defineProps<
    ICheckboxProps
>(), {
  name: '',
  disabled: false,
  error: '',
  label: '',
  modelValue: false,
  theme: ECoreCheckboxTheme.Primary
})

const { errorMessage, handleChange } = useField(
  () => props.name,
  undefined,
  {
    syncVModel: true,
    validateOnValueUpdate: false
  }
)
</script>
