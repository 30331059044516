import { RouteRecordRaw } from 'vue-router'
import { ECoreIconBoList } from '@common/core-ui'

import config from '@/config'
import { ELoginMethod } from '@/config/config.d'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import LoginLayout from '@/layouts/LoginLayout.vue'
import { useAuthStore } from '@/stores/auth'
import { EBankValidationRequestListState, EBankValidationRequestMode } from '@/types/bank-validation-request.d'
import { EPermission } from '@/types/permission.d'

import AccountId from '@/pages/accounts/account-id/AccountId.vue'
import AccountCardOrder from '@/pages/accounts/account-id/tabs/cards/AccountCardOrder.vue'
import AccountCards from '@/pages/accounts/account-id/tabs/cards/AccountCards.vue'
import AccountInformation from '@/pages/accounts/account-id/tabs/information/AccountInformation.vue'
import AccountOperations from '@/pages/accounts/account-id/tabs/operations/AccountOperations.vue'
import AccountSettings from '@/pages/accounts/account-id/tabs/settings/AccountSettings.vue'
import AccountSettingsFee from '@/pages/accounts/account-id/tabs/settings/AccountSettingsFee.vue'
import AccountSettingsLimits from '@/pages/accounts/account-id/tabs/settings/AccountSettingsLimits.vue'
import AccountSettingsTemporaryLimits from '@/pages/accounts/account-id/tabs/settings/AccountSettingsTemporaryLimits.vue'
import AccountUsers from '@/pages/accounts/account-id/tabs/users/AccountUsers.vue'
import AccountCreate from '@/pages/accounts/create/AccountCreate.vue'
import AccountsView from '@/pages/accounts/list/AccountsList.vue'
import BankValidationRequestsList from '@/pages/bank-validation-requests/BankValidationRequestsList.vue'
import BankValidationRequestsView from '@/pages/bank-validation-requests/BankValidationRequestsView.vue'
import CardId from '@/pages/cards/card-id/CardId.vue'
import CardInformation from '@/pages/cards/card-id/tabs/information/CardInformation.vue'
import CardOperations from '@/pages/cards/card-id/tabs/operations/CardOperations.vue'
import CardAdvanced from '@/pages/cards/card-id/tabs/settings/blocks/CardAdvanced.vue'
import CardAlert from '@/pages/cards/card-id/tabs/settings/blocks/CardAlert.vue'
import CardAuthorizationCategory from '@/pages/cards/card-id/tabs/settings/blocks/CardAuthorizationCategory.vue'
import CardAuthorizationRange from '@/pages/cards/card-id/tabs/settings/blocks/CardAuthorizationRange.vue'
import CardControl from '@/pages/cards/card-id/tabs/settings/blocks/CardControl.vue'
import CardLimit from '@/pages/cards/card-id/tabs/settings/blocks/CardLimit.vue'
import CardSecurity from '@/pages/cards/card-id/tabs/settings/blocks/CardSecurity.vue'
import CardSmartCard from '@/pages/cards/card-id/tabs/settings/blocks/CardSmartCard.vue'
import CardSetting from '@/pages/cards/card-id/tabs/settings/CardSetting.vue'
import DashboardView from '@/pages/dashboard/DashboardView.vue'
import InvitationId from '@/pages/invitations/user-id/InvitationId.vue'
import InvitationInformation from '@/pages/invitations/user-id/tabs/information/InvitationInformation.vue'
import InvitationRights from '@/pages/invitations/user-id/tabs/rights/InvitationRights.vue'
import LoginForm from '@/pages/login/LoginForm.vue'
import PasswordRecovery from '@/pages/login/PasswordRecovery.vue'
import PasswordUpdate from '@/pages/login/PasswordUpdate.vue'
import OperationsView from '@/pages/operations/OperationsView.vue'
import UserInformation from '@/pages/users/user-id/tabs/information/UserInformation.vue'
import UserRights from '@/pages/users/user-id/tabs/rights/UserRights.vue'
import UserSca from '@/pages/users/user-id/tabs/sca/UserSca.vue'
import UserId from '@/pages/users/user-id/UserId.vue'

let routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    redirect: () => {
      if (useAuthStore().isTokenExpired) {
        return { name: 'login' }
      } else {
        return { name: 'dashboard' }
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: () => {
      if (useAuthStore().isTokenExpired) {
        return { name: 'login' }
      } else {
        return { name: 'dashboard' }
      }
    }
  },
  {
    path: '/login/:client?',
    component: LoginLayout,
    children: [
      {
        path: '',
        component: LoginForm,
        name: 'login'
      }
    ]
  },
  {
    path: '/dashboard',
    component: DefaultLayout,
    meta: {
      requiresAuth: true,
      permissions: [EPermission.DashboardRead]
    },
    children: [
      {
        path: '',
        component: DashboardView,
        name: 'dashboard',
        meta: {
          menu: {
            label: 'dashboard',
            icon: ECoreIconBoList.BoGrid
          }
        }
      }
    ]
  },
  {
    path: '/accounts',
    component: DefaultLayout,
    meta: {
      requiresAuth: true,
      permissions: [EPermission.AccountRead]
    },
    children: [
      {
        path: '',
        component: AccountsView,
        name: 'accounts',
        props: route => ({
          page: parseInt(route.query.page as string || '1'),
          search: route.query.search as string | undefined
        }),
        meta: {
          menu: {
            label: 'accounts',
            icon: ECoreIconBoList.BoUsers
          }
        }
      },
      {
        path: 'create',
        component: AccountCreate,
        name: 'account-create',
        meta: {
          permissions: [EPermission.AccountCreate]
        }
      },

      {
        path: ':uuid',
        component: AccountId,
        meta: {
          tabs: true
        },
        props: route => ({ uuid: route.params.uuid }),
        children: [
          {
            path: '',
            component: AccountInformation,
            name: 'account-information',
            meta: {
              tab: { label: 'information' }
            },
            props: route => ({ accountDetails: route.params.accountDetails })
          },
          {
            path: 'cards',
            meta: {
              permissions: [EPermission.AccountCardRead],
              tab: { label: 'cards' }
            },
            children: [
              {
                path: '',
                name: 'account-cards',
                component: AccountCards,
                props: route => ({
                  accountUuid: route.params.uuid,
                  page: parseInt(route.query.page as string || '1'),
                  accountDetails: route.params.accountDetails
                })
              },
              {
                path: 'order',
                name: 'account-card-order',
                component: AccountCardOrder,
                props: route => ({
                  accountUuid: route.params.uuid,
                  accountDetails: route.params.accountDetails
                })
              }
            ]
          },
          {
            path: 'users',
            component: AccountUsers,
            name: 'account-users',
            meta: {
              permissions: [EPermission.AccountUserRead],
              tab: { label: 'users' }
            },
            props: route => ({
              accountUuid: route.params.uuid,
              page: parseInt(route.query.page as string || '1'),
              state: route.query.state || ''
            })
          },
          {
            path: 'operations',
            component: AccountOperations,
            name: 'account-operations',
            meta: {
              permissions: [EPermission.OperationRead],
              tab: { label: 'operations' }
            },
            props: route => ({
              accountUuid: route.params.uuid,
              page: parseInt(route.query.page as string || '1')
            })
          },
          {
            path: 'settings',
            meta: {
              tab: { label: 'settings' },
              permissions: [
                EPermission.AccountTransactionAmountMonthlyLimitUpdate
              ]
            },
            children: [
              {
                path: '',
                component: AccountSettings,
                name: 'account-settings'
              },
              {
                path: 'limits',
                component: AccountSettingsLimits,
                name: 'account-settings-limits',
                meta: {
                  permissions: [EPermission.AccountTransactionAmountMonthlyLimitUpdate]
                },
                props: route => ({ accountUuid: route.params.uuid })
              },
              {
                path: 'temporary-limits',
                component: AccountSettingsTemporaryLimits,
                name: 'account-settings-temporary-limits',
                meta: {
                  permissions: [EPermission.AccountTransactionAmountTemporaryUpdate]
                },
                props: route => ({ accountUuid: route.params.uuid })
              },
              {
                path: 'fee',
                component: AccountSettingsFee,
                name: 'account-settings-fee',
                meta: {
                  permissions: [EPermission.AccountFeeWorldlineWrite]
                },
                props: route => ({ accountUuid: route.params.uuid })
              }
            ]
          }
        ]
      },
      {
        path: 'cards',
        children: [
          {
            path: ':uuid',
            component: CardId,
            meta: {
              permissions: [EPermission.CardRead],
              tabs: true
            },
            props: route => ({ uuid: route.params.uuid }),
            children: [
              {
                path: '',
                component: CardInformation,
                name: 'card-information',
                meta: { tab: { label: 'information' } },
                props: route => ({ cardDetails: route.params.cardDetails })
              },
              {
                path: 'operations',
                component: CardOperations,
                name: 'card-operations',
                meta: {
                  permissions: [EPermission.CardOperationRead],
                  tab: { label: 'operations' }
                },
                props: route => ({
                  cardUuid: route.params.uuid,
                  page: parseInt(route.query.page as string || '1')
                })
              },
              {
                path: 'setting',
                component: CardSetting,
                name: 'card-setting',
                meta: {
                  navs: true,
                  permissions: [EPermission.CardSettingRead],
                  tab: { label: 'settings' }
                },
                props: route => ({
                  cardUuid: route.params.uuid
                }),
                children: [
                  {
                    path: 'security',
                    name: 'card-security-setting',
                    component: CardSecurity,
                    meta: {
                      nav: { label: 'security_settings' },
                      permissions: [EPermission.CardSettingSecurityRead]
                    }
                  },
                  {
                    path: 'limit',
                    name: 'card-limit-setting',
                    component: CardLimit,
                    meta: {
                      nav: { label: 'limits' },
                      permissions: [EPermission.CardSettingLimitRead]
                    }
                  },
                  {
                    path: 'control',
                    name: 'card-control-setting',
                    component: CardControl,
                    meta: {
                      nav: { label: 'control_rules' },
                      permissions: [
                        // EPermission.CardSettingControlRead
                      ]
                    }
                  },
                  {
                    path: 'alert',
                    name: 'card-alert-setting',
                    component: CardAlert,
                    meta: {
                      nav: { label: 'email_alerts' },
                      permissions: [EPermission.CardSettingAlertRead]
                    }
                  },
                  {
                    path: 'range',
                    name: 'card-authorization-range-setting',
                    component: CardAuthorizationRange,
                    meta: {
                      nav: { label: 'days_and_time_ranges' },
                      permissions: [EPermission.CardSettingAuthorizationRangeRead]
                    }
                  },
                  {
                    path: 'category',
                    name: 'card-authorization-category-setting',
                    component: CardAuthorizationCategory,
                    meta: {
                      nav: { label: 'categories' },
                      permissions: [EPermission.CardSettingAuthorizationCategoryRead]
                    }
                  },
                  {
                    path: 'smart-card',
                    name: 'card-smart-card-setting',
                    component: CardSmartCard,
                    meta: {
                      nav: { label: 'smart_card' },
                      permissions: [
                        EPermission.CardSettingSmartCardRead
                      ]
                    }
                  },
                  {
                    path: 'advanced',
                    name: 'card-advanced-setting',
                    component: CardAdvanced,
                    meta: {
                      nav: { label: 'advanced_settings' },
                      permissions: [EPermission.CardSettingAdvancedRead]
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'users',
        children: [
          {
            path: ':uuid',
            component: UserId,
            meta: {
              permissions: [EPermission.UserRead],
              tabs: true
            },
            props: route => ({
              uuid: route.params.uuid,
              accountUuid: route.query.account_uuid
            }),
            children: [
              {
                path: '',
                component: UserInformation,
                name: 'user-information',
                props: route => ({ userDetails: route.params.userDetails }),
                meta: {
                  keepQuery: ['account_uuid'],
                  tab: { label: 'information' }
                }
              },
              {
                path: 'rights',
                component: UserRights,
                name: 'user-rights',
                meta: {
                  keepQuery: ['account_uuid'],
                  permissions: [EPermission.UserRightRead],
                  tab: { label: 'rights' }
                },
                props: route => ({
                  userDetails: route.params.userDetails,
                  accountUuid: route.query.account_uuid
                })
              },
              {
                path: 'sca',
                component: UserSca,
                name: 'user-sca',
                meta: {
                  keepQuery: ['account_uuid'],
                  permissions: [EPermission.ScaTokenRead],
                  tab: { label: 'sca' }
                },
                props: route => ({
                  userDetails: route.params.userDetails
                })
              }
            ]
          }
        ]
      },
      {
        path: 'invitations',
        children: [
          {
            path: ':uuid',
            component: InvitationId,
            meta: {
              permissions: [EPermission.InvitationRead],
              tabs: true
            },
            props: route => ({
              uuid: route.params.uuid,
              accountUuid: route.query.account_uuid
            }),
            children: [
              {
                path: '',
                component: InvitationInformation,
                name: 'invitation-information',
                meta: {
                  keepQuery: ['account_uuid'],
                  tab: { label: 'information' }
                },
                props: route => ({ invitationDetails: route.params.invitationDetails })
              },
              {
                path: 'rights',
                component: InvitationRights,
                name: 'invitation-rights',
                meta: {
                  keepQuery: ['account_uuid'],
                  permissions: [EPermission.InvitationRightRead],
                  tab: { label: 'rights' }
                },
                props: route => ({
                  invitationDetails: route.params.userDetails
                })
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/operations',
    component: DefaultLayout,
    meta: {
      requiresAuth: true,
      permissions: [EPermission.OperationRead]
    },
    children: [
      {
        path: '',
        component: OperationsView,
        name: 'operations',
        props: route => ({ page: parseInt(route.query.page as string || '1') }),
        meta: {
          menu: {
            label: 'operations',
            icon: ECoreIconBoList.BoCreditCard
          }
        }
      }
    ]
  },
  {
    path: '/requests',
    component: DefaultLayout,
    meta: {
      requiresAuth: true,
      permissions: [EPermission.BankValidationRequestRead]
    },
    children: [
      {
        path: '',
        component: BankValidationRequestsView,
        name: 'bank-validation-requests',
        props: route => ({
          page: parseInt(route.query.page as string || '1')
        }),
        meta: {
          menu: {
            label: 'requests',
            icon: ECoreIconBoList.BoClock
          },
          tabs: true
        },
        children: [
          {
            path: '',
            component: BankValidationRequestsList,
            name: 'to-validate',
            meta: {
              keepQuery: ['mode'],
              tab: { label: 'to_validate' }
            },
            props: route => ({
              route: route.name,
              state: EBankValidationRequestListState.Pending,
              mode: route.query.mode || EBankValidationRequestMode.Client
            })
          },
          {
            path: 'history',
            component: BankValidationRequestsList,
            name: 'history',
            meta: {
              keepQuery: ['mode'],
              tab: { label: 'history' }
            },
            props: route => ({
              route: route.name,
              state: EBankValidationRequestListState.History,
              mode: route.query.mode || EBankValidationRequestMode.Client
            })
          },
          {
            path: 'mine',
            component: BankValidationRequestsList,
            name: 'my-requests',
            meta: {
              tab: { label: 'my_requests' }
            },
            props: route => ({
              route: route.name,
              state: EBankValidationRequestListState.Mine,
              mode: null
            })
          }
        ]
      }
    ]
  }
]

if (config.loginMethod === ELoginMethod.Credentials) {
  routes.push({
    path: '/password',
    component: LoginLayout,
    children: [
      {
        path: 'recovery',
        component: PasswordRecovery,
        name: 'password-recovery'
      },
      {
        path: 'update',
        component: PasswordUpdate,
        props: route => ({ token: route.query.token as string | undefined }),
        name: 'password-update'
      }
    ]
  })
}

const fillParentPermissions = (routes: RouteRecordRaw[], parentPermissions: EPermission[]) => {
  routes.forEach(route => {
    if (parentPermissions.length) {
      if (!route.meta) route.meta = {}
      const uniquePermissions = new Set([
        ...parentPermissions,
        ...(route.meta.permissions || [])
      ])
      route.meta.permissions = Array.from(uniquePermissions)
    }

    if (route.children) {
      route.children = fillParentPermissions(
        route.children,
        route.meta?.permissions && Array.isArray(route.meta.permissions)
          ? [...new Set([...parentPermissions, ...route.meta.permissions])]
          : parentPermissions
      )
    }
  })
  return routes
}

routes = fillParentPermissions(routes, [])

export default routes
