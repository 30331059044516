<template>
  <div class="invitation-information">
    <core-alert v-if="hasBankValidationRequest"
                :icon-name="ECoreIcon.BoInfo"
                :theme="ECoreAlertTheme.Info"
                :text="t('alert.user_bvr')">
      <template #actions>
        <router-link class="core-link--theme-info core-link--underline core-link--bold"
                     data-cy="card-detail.information.bvr.action"
                     :to="{ name: 'to-validate', query: { mode: 'bank' } }">
          {{ t('action.see_details') }}
        </router-link>
      </template>
    </core-alert>
    <core-alert v-if="isInvitationPending"
                class="invitation-information__alert"
                data-cy="account-invitations.detail.pending-alert"
                :bold="false"
                :theme="ECoreAlertTheme.Warning"
                :icon-name="ECoreIcon.BoAlertTriangle"
                :text="pendingAlertContent" />
    <core-alert v-if="isInvitationExpired"
                class="invitation-information__alert"
                data-cy="account-invitations.detail.expired-alert"
                :bold="false"
                :theme="ECoreAlertTheme.GrayMid"
                :icon-name="ECoreIcon.BoAlertTriangle"
                :text="expiredAlertContent">
      <template v-if="hasPermission(EPermission.InvitationRenew)"
                #actions>
        <a class="core-link core-link--underline core-link--bold"
           data-cy="account-invitations.detail.expired-alert.renew-invitation"
           @click="showModal(ModalResendInvitation)">
          {{ t('action.resend_invitation') }}
        </a>
      </template>
    </core-alert>
    <details-block data-cy="account-invitations.detail.information"
                   :title="t('general.information')"
                   :columns="2">
      <template #col-1>
        <details-line :title="t('general.civility')"
                      :data="[gender]" />
        <details-line :title="t('general.first_name')"
                      :data="[firstName]" />
        <details-line :title="t('general.last_name')"
                      :data="[lastName]" />
        <details-line :title="t('general.email')"
                      :data="[mail]" />
        <details-line :title="t('general.phone')"
                      :data="[phone]" />
        <details-line :title="t('general.birth_date')"
                      :data="[birthDate]" />
      </template>
      <template #col-2>
        <details-line v-if="hasPermission(EPermission.UserIdentityCardRead)"
                      :title="t('general.proof_of_identity')"
                      :data="[proofOfIdentity]"
                      :clickable="hasProofOfIdentity"
                      @click="hasProofOfIdentity && appStore.showFileViewer(invitationDetails.user?.identity_card_documents)" />
        <details-line :title="t('general.access_right', 2)">
          <template #data>
            <account-user-access-rights :access-rights="invitationDetails.rights"
                                        :is-main-owner="invitationDetails.is_main_owner" />
          </template>
        </details-line>
        <details-line :title="t('general.creation_date')"
                      :data="[creationDate]" />
        <details-line :title="t('general.last_login_date')"
                      :data="[lastLoginDate]" />
      </template>
    </details-block>
  </div>
</template>

<script setup lang="ts">
import { Component, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreAlert, ECoreAlertTheme, ECoreIcon } from '@common/core-ui'
import dayjs from 'dayjs'

import hasPermission from '@/helpers/permissions'
import { formatDateText, formatDateTime } from '@/helpers/utils/date'
import { useAppStore } from '@/stores/app'
import { EInvitationStatus, IInvitationDetails } from '@/types/invitation.d'
import { EPermission } from '@/types/permission.d'
import { EUserGender } from '@/types/user.d'

import AccountUserAccessRights from '@/pages/accounts/account-id/tabs/users/AccountUserAccessRights.vue'
import ModalResendInvitation from '@/pages/invitations/user-id/modal/ModalResendInvitation.vue'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const { t } = useI18n()
const appStore = useAppStore()

const props = defineProps<{
  invitationDetails: IInvitationDetails,
  isInvitation?: boolean
  hasBankValidationRequest?: boolean
}>()

const gender = computed(() => {
  if (!props.invitationDetails.gender) return '-'
  return t(`general.${EUserGender[props.invitationDetails.gender]?.toLowerCase()}`)
})

const firstName = computed(() => {
  return props.invitationDetails.first_name
})

const lastName = computed(() => {
  return props.invitationDetails.last_name
})

const mail = computed(() => {
  return props.invitationDetails.email
})

const phone = computed(() => {
  if (!props.invitationDetails.phone) return '-'
  return props.invitationDetails.phone
})

const birthDate = computed(() => {
  if (!props.invitationDetails?.user?.birth_date) return '-'
  return formatDateText(dayjs(props.invitationDetails.user.birth_date))
})

const hasProofOfIdentity = computed(() => {
  return !!props.invitationDetails?.user?.identity_card_documents?.length
})

const proofOfIdentity = computed(() => {
  return hasProofOfIdentity.value ? t('general.view_document') : '-'
})

const creationDate = computed(() => {
  return formatDateTime(props.invitationDetails.created_at)
})

const lastLoginDate = computed(() => {
  if (!props.invitationDetails.user?.last_login_at) return '-'
  return formatDateTime(props.invitationDetails.user.last_login_at)
})

const isInvitationPending = computed(() => {
  return props.invitationDetails.status === EInvitationStatus.Pending
})

const isInvitationExpired = computed(() => {
  return props.invitationDetails.status === EInvitationStatus.Expired
})

const pendingAlertContent = computed(() => {
  return props.invitationDetails.sent_at
    ? t('alert.invitation_pending', { date: formatDateText(props.invitationDetails.sent_at) }, 1)
    : t('alert.invitation_pending', 2)
})

const expiredAlertContent = computed(() => {
  return props.invitationDetails.sent_at
    ? t('alert.invitation_expired', { date: formatDateText(props.invitationDetails.sent_at) }, 1)
    : t('alert.invitation_expired', 2)
})

function showModal (modal: Component) {
  appStore.showModal(modal, {
    invitationDetails: props.invitationDetails
  })
}

</script>

<style lang="stylus" scoped>
.invitation-information
  display flex
  flex-direction column
  gap 2rem

  .invitation-information__alert
    font-weight 400

    .core-link
      font-size 1.55rem
</style>
