<template>
  <section>
    <section-header :title="t('general.create_customer_account')"
                    go-back-route="accounts" />
    <section-body>
      <template #body>
        <validation-form v-slot="{ resetField }"
                         data-cy="account.create.form"
                         :validation-schema="accountCreateSchema"
                         @submit="onSubmit">
          <details-block :header-size="EDefaultSize.Large"
                         :title="stepTitle"
                         :step="`${stepIndex + 1}/${availableSteps.length}`"
                         :columns="1">
            <template v-if="currentStep === EFormStep.CustomerInformation"
                      #col-1>
              <form-line :title="t('general.department')">
                <template #input>
                  <select-dropdown-api v-model:model-value="accountCreateForm.company.department.uuid"
                                       name="department"
                                       data-cy="account.create.form.department"
                                       min-width="100%"
                                       :async-request="getDepartments"
                                       :size="ECoreInputSize.Medium"
                                       items-key="uuid"
                                       :search-enabled="false"
                                       @update-selected-items="async (items) => { await resetField('external_ref'); onUpdateDepartment(items) }">
                    <template #label="{ selectedItems, isEmpty }">
                      {{ isEmpty ? t('placeholder.select') : selectedItems?.[0]?.label }}
                    </template>
                  </select-dropdown-api>
                </template>
              </form-line>

              <form-line :title="t('general.account_number')">
                <template #input>
                  <input-text v-model:model-value="accountCreateForm.account.external_ref"
                              name="external_ref"
                              data-cy="account.create.form.external-ref"
                              :max-length="15"
                              :placeholder="t('placeholder.account_number')" />
                </template>
              </form-line>

              <form-line :title="t('general.iban')">
                <template #input>
                  <input-text v-model:model-value="accountCreateForm.account.iban"
                              name="iban"
                              data-cy="account.create.form.iban"
                              :placeholder="t('placeholder.iban')"
                              @keyup="onIbanKeyUp" />
                </template>
              </form-line>

              <form-line :title="t('general.company_information')" />

              <form-line :title="t('general.legal_status')">
                <template #input>
                  <select-dropdown v-model:model-value="accountCreateForm.company.type"
                                   name="company_type"
                                   data-cy="account.create.form.company-type"
                                   :size="ECoreInputSize.Medium"
                                   min-width="100%"
                                   :placeholder="t('placeholder.legal_status')"
                                   :items="companyTypeList" />
                </template>
              </form-line>

              <form-line v-if="accountCreateForm.company.type === EAccountCompanyType.LegalPerson"
                         :title="t('general.company_name')">
                <template #input>
                  <input-text v-model:model-value="accountCreateForm.company.name"
                              name="company_name"
                              data-cy="account.create.form.company-name"
                              :placeholder="t('placeholder.company_name')" />
                </template>
              </form-line>

              <form-line :title="t('general.owner_information')" />
              <form-line :title="t('general.civility')">
                <template #input>
                  <div class="user-gender">
                    <validation-field v-slot="field"
                                      v-model:model-value="accountCreateForm.company.user.gender"
                                      name="user_gender">
                      <core-input-radio v-bind="field"
                                        data-cy="account.create.form.gender.mr"
                                        :size="ECoreInputSize.Medium"
                                        :label="t('general.mr')"
                                        :value="1" />
                      <core-input-radio v-bind="field"
                                        data-cy="account.create.form.gender.mrs"
                                        :size="ECoreInputSize.Medium"
                                        :label="t('general.mrs')"
                                        :value="2" />
                    </validation-field>
                  </div>
                </template>
              </form-line>

              <form-line :title="t('general.first_name')">
                <template #input>
                  <input-text v-model:model-value="accountCreateForm.company.user.first_name"
                              data-cy="account.create.form.first-name"
                              :placeholder="t('placeholder.first_name')"
                              name="first_name" />
                </template>
              </form-line>
              <form-line :title="t('general.last_name')">
                <template #input>
                  <input-text v-model:model-value="accountCreateForm.company.user.last_name"
                              name="last_name"
                              data-cy="account.create.form.last-name"
                              :placeholder="t('placeholder.last_name')" />
                </template>
              </form-line>
              <form-line :title="t('general.email')">
                <template #input>
                  <input-text v-model:model-value="accountCreateForm.company.user.email"
                              name="user_email"
                              data-cy="account.create.form.email"
                              :placeholder="t('placeholder.email')" />
                </template>
              </form-line>
              <form-line :title="t('general.phone')">
                <template #input>
                  <input-phone-number v-model:model-value="accountCreateForm.company.user.phone"
                                      name="user_phone"
                                      data-cy="account.create.form.phone"
                                      :locale="locale"
                                      placeholder="" />
                </template>
              </form-line>
              <form-line :title="t('general.birth_date')">
                <template #input>
                  <date-picker v-model:model-value="accountCreateForm.company.user.birth_date"
                               data-cy="account.create.form.birth-date"
                               format="dd/MM/yyyy"
                               name="birth_date"
                               :placeholder="t('placeholder.date_format')"
                               :locale="locale"
                               :disabled-days="{ from: new Date() }"
                               :size="ECoreInputSize.Medium" />
                </template>
              </form-line>
              <div class="kyc-checkbox">
                <input-checkbox v-model:model-value="kycChecked"
                                placeholder=""
                                name="kyc"
                                data-cy="account.create.form.kyc"
                                :label="t('general.create_customer_account_kyc')" />
              </div>
            </template>
            <template v-else-if="currentStep === EFormStep.LimitSetting"
                      #col-1>
              <form-line :title="t('account.setting.limit.monthly_limit')">
                <template #input>
                  <input-number v-model:model-value="accountCreateForm.account.settings.transaction_amount_monthly_limit"
                                name="transaction_amount_monthly_limit"
                                data-cy="account.create.form.transaction-amount-monthly-limit"
                                :max-length="12"
                                placeholder="">
                    <template #append>
                      {{ getCurrencySymbol(currency) }}
                    </template>
                  </input-number>
                </template>
              </form-line>
              <form-line :title="t('account.setting.limit.monthly_alert_threshold')">
                <template #input-header>
                  <core-toggle-switch v-model:model-value="transactionAmountMonthlyAlertThresholdEnabled"
                                      data-cy="account.create.form.transaction-amount-alert-threshold-percentage.toggle"
                                      :label="t('general.rate_range', { min: 50, max: 100 })" />
                </template>
                <template v-if="transactionAmountMonthlyAlertThresholdEnabled"
                          #input>
                  <input-number v-model:model-value="accountCreateForm.account.settings.transaction_amount_monthly_alert_threshold_percentage"
                                name="transaction_amount_monthly_alert_threshold_percentage"
                                data-cy="account.create.form.transaction-amount-alert-threshold-percentage.input"
                                placeholder="">
                    <template #append>
                      %
                    </template>
                  </input-number>
                </template>
                <template v-if="transactionAmountMonthlyAlertThreshold"
                          #input-addon>
                  <div>
                    <core-icon class="input-addon-icon"
                               :name="ECoreIconList.InfoOutlined"
                               :theme="ECoreIconTheme.GrayMid"
                               :size="16" />
                    {{ t('general.ie') }} {{ formatAmount(transactionAmountMonthlyAlertThreshold, currency) }}
                  </div>
                </template>
              </form-line>
            </template>

            <template #footer-right>
              <core-button class="mr-1"
                           data-cy="account.create.form.cancel"
                           :text="isFirstStep ? t('action.cancel') : t('action.previous')"
                           :theme="ECoreButtonTheme.Transparent"
                           @click="cancelForm" />
              <core-button :text="isLastStep ? t('action.validate') : t('action.next')"
                           data-cy="account.create.form.submit"
                           type="submit"
                           :disabled="isLoading"
                           :theme="ECoreButtonTheme.Primary"
                           :loading="isLoading" />
            </template>
          </details-block>
        </validation-form>
      </template>
    </section-body>
  </section>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import {
  CoreButton,
  CoreIcon,
  CoreInputRadio,
  CoreToggleSwitch,
  ECoreButtonTheme,
  ECoreIconList,
  ECoreIconTheme,
  ECoreInputSize,
  TDropdownItem
} from '@common/core-ui'
import _ from 'lodash'
import * as yup from 'yup'

import bus from '@/helpers/eventBus'
import { formatIbanOnKeyUp } from '@/helpers/utils/iban'
import { formatAmount, getCurrencySymbol } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useDepartmentStore } from '@/stores/department'
import { EAccountCompanyType, IAccountForm } from '@/types/account.d'
import { EAccountCreateEvent } from '@/types/eventBus.d'
import { EDefaultSize } from '@/types/size.d'

import ModalAccountCreate from '@/pages/accounts/modal/ModalAccountCreate.vue'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import FormLine from '@/components/form/FormLine.vue'
import ValidationField from '@/components/form/ValidationField.vue'
import DatePicker from '@/components/inputs/DatePicker.vue'
import InputCheckbox from '@/components/inputs/InputCheckbox.vue'
import InputNumber from '@/components/inputs/InputNumber.vue'
import InputPhoneNumber from '@/components/inputs/InputPhoneNumber.vue'
import InputText from '@/components/inputs/InputText.vue'
import SelectDropdown from '@/components/inputs/SelectDropdown.vue'
import SelectDropdownApi from '@/components/inputs/SelectDropdownApi.vue'
import SectionBody from '@/components/section/SectionBody.vue'
import SectionHeader from '@/components/section/SectionHeader.vue'

const { t, locale } = useI18n()
const accountStore = useAccountStore()
const departmentStore = useDepartmentStore()
const router = useRouter()
const currency = {
  iso: 'EUR',
  label: 'Euro',
  fraction_digits: 2,
  flag_url: 'none'
}

const accountCreateForm = ref<IAccountForm>({
  company: {
    type: null,
    department: {
      label: '',
      uuid: ''
    },
    name: '',
    user: {
      birth_date: '',
      email: '',
      gender: null,
      first_name: '',
      last_name: '',
      phone: ''
    }
  },
  account: {
    external_ref: '',
    iban: '',
    settings: {
      transaction_amount_monthly_limit: 10000,
      transaction_amount_monthly_alert_threshold_percentage: null
    }
  }
})

const kycChecked = ref<boolean>(false)

// Steps
enum EFormStep {
  CustomerInformation = 'customer-information',
  LimitSetting = 'limit-setting',
}

const currentStep = ref<EFormStep>(EFormStep.CustomerInformation)
const selectedDepartmentLabel = ref<string>('')

const isLoading = ref<boolean>(false)

const availableSteps = computed(() => {
  return [
    EFormStep.CustomerInformation,
    EFormStep.LimitSetting
  ].filter(e => e)
})

const stepIndex = computed(() => {
  return availableSteps.value.findIndex(step => step === currentStep.value)
})

const stepTitle = computed(() => {
  switch (currentStep.value) {
    case EFormStep.CustomerInformation: return t('general.customer_information')
    case EFormStep.LimitSetting: return t('account.setting.limit.title')
    default: return ''
  }
})

const isFirstStep = computed(() => {
  return stepIndex.value === 0
})
const isLastStep = computed(() => {
  return stepIndex.value === availableSteps.value.length - 1
})

const companyTypeList = computed(() => {
  let types = [
    EAccountCompanyType.PhysicalPerson,
    EAccountCompanyType.LegalPerson
  ]
  types = selectedDepartmentLabel.value === 'Corporate'
    ? types.filter(type => type !== EAccountCompanyType.PhysicalPerson)
    : types
  return types.map(type => ({ label: t(`general.${type}`), value: type }))
})

const accountCreateSchema = computed(() => {
  return {
    [EFormStep.CustomerInformation]: yup.object({
      external_ref: yup.string().trim()
        .max(16).required().when('department', (type, schema) => {
          return departmentRegExp.value
            ? schema.matches(departmentRegExp.value)
            : schema
        }),
      department: yup.string().required(),
      iban: yup.string().iban().required(),
      company_name: yup.string().when('company_type', {
        is: (type: string) => type === EAccountCompanyType.LegalPerson,
        then: schema => schema.required()
      }),
      company_type: yup.string().required(),
      user_gender: yup.number().oneOf([1, 2]),
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      user_email: yup.string().email().required(),
      user_phone: yup.string().phone().required(),
      birth_date: yup.string().required(),
      kyc: yup.boolean().required().oneOf([true])
    }),
    [EFormStep.LimitSetting]: yup.object({
      transaction_amount_monthly_alert_threshold_percentage: yup.number().nullable().min(50).max(100),
      transaction_amount_monthly_limit: yup.number().required().min(1)
    })
  }[currentStep.value]
})

onMounted(() => {
  bus.on(EAccountCreateEvent.ConfirmAccountCreate, onAccountCreateConfirm)
})

onUnmounted(() => {
  bus.off(EAccountCreateEvent.ConfirmAccountCreate, onAccountCreateConfirm)
})

const transactionAmountMonthlyAlertThresholdEnabled = computed({
  get (): boolean {
    return accountCreateForm.value?.account.settings.transaction_amount_monthly_alert_threshold_percentage !== null
  },
  set (value: boolean) {
    accountCreateForm.value.account.settings.transaction_amount_monthly_alert_threshold_percentage = value ? 50 : null
  }
})

const transactionAmountMonthlyAlertThreshold = computed(() => {
  if (accountCreateForm.value.account.settings.transaction_amount_monthly_limit &&
    accountCreateForm.value.account.settings.transaction_amount_monthly_alert_threshold_percentage) {
    return accountCreateForm.value.account.settings.transaction_amount_monthly_limit * accountCreateForm.value.account.settings.transaction_amount_monthly_alert_threshold_percentage / 100
  }
  return null
})

async function getDepartments () {
  return await departmentStore.getDepartments()
}

function cancelForm () {
  if (!isFirstStep.value) {
    currentStep.value = availableSteps.value[stepIndex.value - 1]
    return
  }
  router.push({ name: 'accounts' })
}

function onSubmit () {
  if (!isLastStep.value) {
    currentStep.value = availableSteps.value[stepIndex.value + 1]
    return
  }
  isLoading.value = true
  useAppStore().showModal(ModalAccountCreate, { accountCreateForm: accountCreateForm.value })
}

async function onAccountCreateConfirm () {
  const payload = _.cloneDeep(accountCreateForm.value)
  payload.account.iban = payload.account.iban.replace(/\s/g, '')

  const { data, error } = await accountStore.createAccount(payload as IAccountForm)
  isLoading.value = false
  if (!error) {
    if (data?.uuid) {
      await router.push({ name: 'account-information', params: { uuid: data.uuid } })
    } else {
      await router.push({ name: 'accounts' })
    }
  }
}

// Department/ExternalRef RegExp
const departmentRegExp = ref<RegExp|null>(null)

function onUpdateDepartment (items: TDropdownItem[]) {
  selectedDepartmentLabel.value = items[0]?.label as string || ''
  if (selectedDepartmentLabel.value === 'Corporate' && accountCreateForm.value.company.type === EAccountCompanyType.PhysicalPerson) {
    accountCreateForm.value.company.type = null
  }
  if (import.meta.env.VITE_CLIENT_BRAND === 'qnb') {
    const prefix = {
      Corporate: '4401',
      Retail: '4406',
      Private: '4403'
    }[items[0].label] || null
    departmentRegExp.value = prefix ? new RegExp(`^${prefix}-?\\d{6}-?\\d{3}$`) : null

    if (prefix && departmentRegExp.value && !accountCreateForm.value.account.external_ref?.match(departmentRegExp.value)) {
      accountCreateForm.value.account.external_ref = prefix
    }
  }
}

const onIbanKeyUp = async (e: KeyboardEvent) => {
  if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
    return
  }
  const target = e.target as HTMLInputElement
  const cursorPosition = target.selectionStart
  const formattedIban = formatIbanOnKeyUp(target.value)
  if (formattedIban) {
    accountCreateForm.value.account.iban = formattedIban
    await nextTick(() => {
      setCursorPosition(e, cursorPosition)
    })
  }
}

const setCursorPosition = (e: KeyboardEvent, cursorPosition: number | null) => {
  if (cursorPosition === null) return
  const target = e.target as HTMLInputElement
  if (e.key === 'Backspace' && cursorPosition > 0 && cursorPosition % 5 === 0) {
    cursorPosition -= 1
  } else if (!/[a-z0-9]/i.test(e.key)) {
    cursorPosition -= 1
  } else if (cursorPosition > 0 && cursorPosition % 5 === 0) {
    cursorPosition += 1
  }
  target.setSelectionRange(cursorPosition, cursorPosition)
}
</script>

<style scoped lang="stylus">
.user-gender
  display flex

  div
    margin-right 2.8rem

.kyc-checkbox
  padding 2.3rem 0
  margin-top 2rem
  margin-left 24rem

.input-addon-icon
  vertical-align sub
</style>
