<template>
  <core-modal active
              :title="t(`general.create_customer_account`)"
              :closable-button="false"
              data-cy="account.create.form.modal">
    <template #body>
      <p class="pre-line">
        {{
          t('modal.create_customer_account.content', {
            account_name: accountCreateForm.company.name,
            amount: formatAmount(accountCreateForm.account.settings.transaction_amount_monthly_limit, { iso: 'EUR' })
          })
        }}
      </p>
    </template>
    <template #footer>
      <core-button :theme="ECoreButtonTheme.Transparent"
                   :appearance="ECoreButtonAppearance.Filled"
                   :text="t('action.cancel')"
                   @click="closeModal" />
      <core-button data-cy="account.create.form.modal.validate"
                   :text="t(`action.create`)"
                   @click="onSubmit" />
    </template>
  </core-modal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  CoreButton,
  CoreModal,
  ECoreButtonAppearance,
  ECoreButtonTheme
} from '@common/core-ui'

import bus from '@/helpers/eventBus'
import { formatAmount } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { IAccountForm } from '@/types/account.d'
import { EAccountCreateEvent } from '@/types/eventBus.d'

const { t } = useI18n()

defineProps<{
  accountCreateForm: IAccountForm
}>()

const appStore = useAppStore()

function closeModal () {
  appStore.closeModal()
}

async function onSubmit () {
  bus.emit(EAccountCreateEvent.ConfirmAccountCreate)
  appStore.closeModal()
}
</script>
