import { IUserLight } from '@/types/user.d'

import { EPhysicalCardType, EVirtualCardType } from './card.d'
import { IDepartment } from './department.d'
import { IParams } from './params.d'

export enum EAccountStatus {
  Valid = 'valid',
  Blocked = 'blocked',
  Closed = 'closed',
  Validating = 'validating',
  Closing = 'closing',
  Deleted = 'deleted'
}

export enum ETransactionMonthlyLimit {
  // NoLimit = 'no_limit',
  Unreached = 'unreached',
  AlertReached = 'alert_reached',
  LimitReached = 'limit_reached'
}

export enum EAccountCompanyType {
  LegalPerson = 'legal_person',
  PhysicalPerson = 'physical_person',
  Association = 'association',
  Company = 'company',
  EI = 'ei',
  Virtual = 'virtual'
}

export interface IAccountsParams extends IParams {
  departments?: IDepartment.uuid[]
  status?: EAccountStatus[]
  exclude_status?: EAccountStatus[]
  transaction_monthly_limit?: ETransactionMonthlyLimit
}

export interface IAccountCompany {
  department?: IDepartment
  name: string
  type: EAccountCompanyType
  user?: IUserLight
  uuid: string
}

export interface IAccountTemporaryLimit {
  enabled: boolean
  end_date: string | null
  limit: number | null
  start_date: string | null
}

export interface IAccountTemporaryLimitPayload {
  transaction_amount_temporary_enabled: boolean
  transaction_amount_temporary_limit: number | null
  transaction_amount_temporary_start_date: string | null
  transaction_amount_temporary_end_date: string | null
}

export interface IAccountSettings {
  transaction_amount_monthly_limit: number
  transaction_amount_monthly_alert_threshold_percentage: number | null
  transaction_amount_monthly_alert_threshold_percentage_enabled: boolean
  transaction_amount_monthly_consumed?: number
  transaction_amount_monthly_excess_allowed_percentage_enabled: boolean
  transaction_amount_monthly_excess_allowed_percentage: number
  transaction_amount_temporary?: IAccountTemporaryLimit
}

export interface IAccount {
  uuid: string
  label: string
  external_ref: string
  iban: string
  company: IAccountCompany
  status: EAccountStatus
  settings?: IAccountSettings
  worldline_membership_fees?: {
    IAccountFeeSettings
  }
}

export interface IAccountLight {
  uuid: string
  label: string
  external_ref: string
  status: EAccountStatus
}

export interface IAccountData {
  uuid: string
  name: string
  external_ref: string
  email: string | undefined
  settings?: IAccountSettings
  status: EAccountStatus
}

export interface IAccountDetails extends IAccount {
  counts: {
    cards: {
      types: {
        [key in EPhysicalCardType | EVirtualCardType]: {
          total: number
          active: number
        }
      }
    }
  },
  card_types: {
    physical: EPhysicalCardType[]
    virtual: EVirtualCardType[]
  }
}

export function isExcessAllowed (settings: IAccountSettings): boolean {
  return settings.transaction_amount_monthly_excess_allowed_percentage !== undefined
}

export interface IAccountFeeSettings {
  [EPhysicalCardType]: string
}

export interface IAccountForm {
  account: {
    external_ref: string
    iban: string
    settings: {
      transaction_amount_monthly_limit: number
      transaction_amount_monthly_alert_threshold_percentage: number | null
    }
    worldline_membership_fees?: IAccountFeeSettings
  }
  company: {
    name: string|null
    type: EAccountCompanyType.LegalPerson | EAccountCompanyType.PhysicalPerson | null
    department: {
      uuid: string
      label: string
    }
    user: {
      gender: 1|2|null
      first_name: string
      last_name: string
      email: string
      phone: string
      birth_date: string
    }
  }
}
