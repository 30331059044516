<template>
  <div class="sca"
       data-cy="account-users.detail.sca">
    <core-table data-cy="sca.table"
                :table-head="tableHead"
                :table-data="scaData"
                show-total-count
                :loading="loading"
                :placeholder-text="t('placeholder.no_sca_found')">
      <template #head-left>
        <span class="title--sm">
          {{ t('general.sca_list') }}
        </span>
      </template>
      <template #type="{ type }">
        <core-icon v-tooltip="{ content: type, theme: 'tooltip' }"
                   :size="20"
                   :theme="ECoreIconTheme.Black"
                   :name="getIconName(type)" />
      </template>
      <template #informations="{ sca_name, created_at }">
        <div class="sca__informations">
          <span>{{ sca_name }}</span>
          <span>{{ t('general.added_on_f') }} {{ formatDateTime(created_at) }}</span>
        </div>
      </template>
      <template #last_used_at="{ last_used_at }">
        <span class="sca__last-used-at">{{ last_used_at ? `${t('general.last_used_on')} ${formatDateTime(last_used_at)}` : '-' }}</span>
      </template>
      <template #action="{ uuid, sca_name, can_be_revoked }">
        <template v-if="canRevokeSca && can_be_revoked">
          <core-icon class="sca__action"
                     data-cy="account-users.detail.sca.revoke"
                     :name="ECoreIconBoList.BoTrash2"
                     :size="20"
                     @click="showScaModal(ModalScaRevoke, uuid, userDetails.uuid, sca_name)" />
        </template>
      </template>
    </core-table>
  </div>
</template>

<script setup lang="ts">
import { Component, computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreIcon,
  CoreTable,
  ECoreIcon,
  ECoreIconBoList,
  ECoreIconTheme
} from '@common/core-ui'

import bus from '@/helpers/eventBus'
import hasPermission from '@/helpers/permissions'
import { formatDateTime } from '@/helpers/utils/date'
import { useAppStore } from '@/stores/app'
import { useUsersStore } from '@/stores/user'
import { EScaEvent } from '@/types/eventBus.d'
import { EPermission } from '@/types/permission.d'
import { EScaType, ISca, IScaData } from '@/types/sca.d'
import { IUserDetails } from '@/types/user.d'

import ModalScaRevoke from '@/pages/users/user-id/tabs/sca/modal/ModalScaRevoke.vue'

const { t } = useI18n()
const userStore = useUsersStore()
const appStore = useAppStore()

const props = defineProps<{
  userDetails: IUserDetails
}>()

const loading = ref(false)
const scaData = ref<IScaData[]>([])

onMounted(async () => {
  loading.value = true
  const scas = await userStore.getUserSca(props.userDetails.uuid)
  scaData.value = formatSca(scas)
  loading.value = false
  bus.on(EScaEvent.Revoke, updateSca)
})

onUnmounted(() => {
  bus.off(EScaEvent.Revoke, updateSca)
})

const tableHead = computed(() => {
  const baseTableHead = [
    {
      id: 'type',
      label: '',
      fitContent: true
    },
    {
      id: 'informations',
      width: 40
    },
    {
      id: 'last_used_at',
      width: 30
    },
    {
      id: 'action',
      fitContent: true
    }
  ]
  return baseTableHead
})

const canRevokeSca = computed(() => {
  return hasPermission(EPermission.ScaTokenRevoke)
})

function formatSca (scas: ISca[]) {
  return scas.map(sca => {
    return {
      uuid: sca.uuid,
      created_at: sca.created_at,
      last_used_at: sca.last_used_at,
      type: sca.type,
      email: sca.token_info.email,
      phone: sca.token_info.phone,
      device_os: sca.token_info.device_os,
      device_name: sca.token_info.device_name,
      sca_name: getScaInformations(sca),
      can_be_revoked: hasPermission(EPermission.ScaTokenRevoke, sca)
    }
  })
}

function getIconName (type: string): ECoreIcon {
  switch (type) {
    case EScaType.APP:
      return ECoreIconBoList.BoSmartphone
    case EScaType.EMAIL:
      return ECoreIconBoList.BoMail
    case EScaType.SMS:
      return ECoreIconBoList.BoPhone
    default:
      throw new Error(`Unknown sca type: ${type}`)
  }
}

function getScaInformations (sca: ISca) {
  switch (sca.type) {
    case EScaType.APP:
      return `${sca.token_info.device_name} ${sca.token_info.device_os}`
    case EScaType.EMAIL:
      return sca.token_info.email
    case EScaType.SMS:
      return sca.token_info.phone
    default:
      throw new Error(`Unknown sca type: ${sca.type}`)
  }
}

function showScaModal (modal: Component, uuid: string, user_uuid: string, sca_name: string) {
  appStore.showModal(modal, {
    uuid,
    userUuid: user_uuid,
    scaName: sca_name
  })
}

function updateSca (uuid: string) {
  scaData.value = scaData.value.filter(sca => sca.uuid !== uuid)
}
</script>
