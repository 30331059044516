<template>
  <div class="account-cards"
       data-cy="account-cards.view">
    <core-table v-model:sort="sort"
                v-model:pagination="pagination"
                v-model:per-page="perPage"
                :per-page-list="config.results.perPageList"
                data-cy="account-cards.table"
                show-total-count
                :table-head="tableHead"
                :table-data="cardsData"
                with-filter
                :loading="loading"
                route="account-cards"
                :placeholder-text="t('placeholder.no_card_found')"
                :current-route="currentRoute"
                with-search-filter
                :search-placeholder="t('placeholder.search')"
                top-pagination
                :locale="locale"
                @update:per-page="getCardsData(true)"
                @row-click="(card) => router.push({ name: 'card-information', params: { uuid: card.uuid as string } })"
                @search-filter="(searchString) => search = searchString"
                @update:sort="getCardsData(true)"
                @update:pagination="getCardsData()">
      <template #head-left>
        <span class="title--sm">
          {{ t('general.cards_list') }}
        </span>
      </template>
      <template #filter-list>
        <account-cards-filters v-model:filters="filters"
                               :available-types="[...accountDetails.card_types.physical, ...accountDetails.card_types.virtual]"
                               :loading="loading"
                               :result-count="pagination.totalItems"
                               @update:filters="getCardsData(true)" />
      </template>
      <template #card="{ type }">
        <component-card-icon :type="type"
                             :size="20" />
      </template>
      <template #digits="{ digits }">
        <span class="account-cards__digits">{{ digits }}</span>
      </template>
      <template #name="{ name }">
        <span>{{ name }}</span>
      </template>
      <template #expiration_date="{ expiration_date }">
        <span>{{ expiration_date }}</span>
      </template>
      <template #type="{ type }">
        <span>{{ t(`card.${type}`) }}</span>
      </template>
      <template #status="{ status }">
        <core-badge :theme="getBadge(status, EBadgeResource.Cards)?.theme"
                    :value="getBadge(status, EBadgeResource.Cards)?.label || ''"
                    :fill="ECoreBadgeFill.Shaded" />
      </template>
      <template #action="{ clickable }">
        <core-icon v-if="clickable"
                   :name="ECoreIconBoList.BoChevronRight"
                   :size="16" />
      </template>
    </core-table>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import {
  CoreBadge,
  CoreIcon,
  CoreTable,
  ECoreBadgeFill,
  ECoreIconBoList,
  ITableSort,
  SortOrder
} from '@common/core-ui'

import config from '@/config'
import { EBadgeResource, getBadge } from '@/helpers/badge'
import { defaultPagination, parsePagination } from '@/helpers/pagination'
import { useCardStore } from '@/stores/card'
import { IAccountDetails } from '@/types/account.d'
import { ICard, ICardData } from '@/types/card.d'

import AccountCardsFilters from '@/pages/accounts/account-id/tabs/cards/AccountCardsFilters.vue'

import ComponentCardIcon from '@/components/ComponentCardIcon.vue'

const { t, locale } = useI18n()
const cardStore = useCardStore()
const currentRoute = useRoute()
const router = useRouter()

const cardsData: Ref<ICardData[]> = ref([])

const props = defineProps<{
  page: number
  accountUuid: string
  accountDetails: IAccountDetails
}>()

const loading = ref(false)

const pagination = ref(defaultPagination)
const perPage = ref(config.results.perPage)

const filters = ref({
  type: [],
  status: [],
  expiration_date: undefined
})

const sort = ref<ITableSort>({
  id: null,
  order: null
})

const search = ref('')

onMounted(async () => {
  await getCardsData()
})

watch(() => props.page, () => getCardsData())
watch(() => search.value, () => getCardsData(true))

const sortParam = computed(() => {
  if (!sort.value.id) {
    return null
  }

  return sort.value.order === SortOrder.Desc ? `-${sort.value.id}` : sort.value.id
})

const tableHead = computed(() => {
  const baseTableHead = [
    {
      id: 'card',
      label: '',
      align: 'center',
      fitContent: true
    },
    {
      id: 'digits',
      label: t('general.card'),
      sortable: false,
      width: 20
    },
    {
      id: 'name',
      label: t('general.holder'),
      sortable: true,
      ellipsis: true,
      width: 20
    },
    {
      id: 'expiration_date',
      label: t('general.expiration_date_short'),
      align: 'left',
      sortable: true,
      width: 10
    },
    {
      id: 'type',
      label: t('general.card_type'),
      align: 'right',
      sortable: true,
      width: 20
    },
    {
      id: 'status',
      label: t('general.status'),
      align: 'right',
      sortable: true,
      width: 10
    },
    {
      id: 'action',
      fitContent: true
    }
  ]
  return baseTableHead
})

async function getCardsData (reset = false) {
  loading.value = true

  const params = {
    ...filters.value,
    account_uuid: props.accountUuid,
    page: reset ? 1 : props.page,
    search: search.value,
    sort: sortParam.value,
    'per-page': perPage.value
  }

  const { data, headers } = await cardStore.getCards(params)
  pagination.value = parsePagination(headers)
  cardsData.value = formatCards(data)

  await router.push({ query: { ...router.currentRoute.value.query, page: pagination.value.current } })
  loading.value = false
}

function formatCards (cards: ICard[]) {
  return cards.map(card => {
    return {
      uuid: card.uuid,
      name: `${card.first_name} ${card.last_name}`,
      digits: card.digits,
      type: card.type,
      expiration_date: card.expiration_date,
      status: card.status,
      clickable: true
    }
  })
}
</script>
