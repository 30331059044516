<template>
  <core-alert v-if="checkRight('owner')"
              class="user-rights__owner-alert"
              :theme="ECoreAlertTheme.Info"
              :icon-name="ECoreIconBoList.BoInfo"
              :bold="false"
              :text="ownerText" />
  <details-block v-if="!isEditing"
                 :loading="loading"
                 :editable="hasPermission(EPermission.UserRightWrite)"
                 :editable-tooltip="checkRight('owner') ? t('general.access_right_edit') : undefined"
                 data-cy="account-users.detail.rights"
                 :title="t('general.access_right', 2)"
                 :columns="2"
                 @edit-click="startEditing">
    <template v-for="index in [1, 2]"
              :key="`col-${index}`"
              #[`col-${index}`]>
      <div>
        <details-line v-for="right in rightsDetails.filter(item => !item.isHidden?.(userRights)).filter((item, i) => getColNumber(i, 2) === index)"
                      :key="right.right"
                      :title="right.title"
                      :data="[checkRight(right.right) ? t('general.yes') : t('general.no')]">
          <template #icon>
            <core-icon v-bind="getIconProps(right.right)" />
          </template>
        </details-line>
      </div>
    </template>
  </details-block>
  <validation-form v-else
                   v-slot="{ meta, isSubmitting }"
                   ref="userRightsForm"
                   :validation-schema="validationSchema"
                   @submit="onSubmit">
    <details-block v-if="editableRights"
                   :title="t('general.access_right', 2)"
                   :loading="loading"
                   :columns="1">
      <template #col-1>
        <form-line v-for="right in editableRightsDetails.filter(item => !item.isHidden?.(editableRights))"
                   :key="right.right"
                   :title="right.title">
          <template #input>
            <validation-field v-slot="field"
                              v-model:model-value="editableRights[right.right]"
                              :name="right.right">
              <core-toggle-switch v-bind="field"
                                  :label="editableRights[right.right] ? t('general.yes') : t('general.no')" />
            </validation-field>
          </template>
        </form-line>
      </template>
      <template #footer-right>
        <core-button :text="t('action.cancel')"
                     :theme="ECoreButtonTheme.Transparent"
                     class="mr-1"
                     @click="cancelForm" />
        <core-button :text="t('action.validate')"
                     data-cy="account-settings.limits.submit"
                     type="submit"
                     :disabled="!meta.dirty || isSubmitting"
                     :theme="ECoreButtonTheme.Primary"
                     :loading="isSubmitting" />
      </template>
    </details-block>
  </validation-form>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreAlert,
  CoreButton,
  CoreIcon,
  CoreToggleSwitch,
  ECoreAlertTheme,
  ECoreButtonTheme,
  ECoreIconBoList,
  ECoreIconTheme,
  TCoreIconSize
} from '@common/core-ui'
import { cloneDeep } from 'lodash'
import * as yup from 'yup'

import bus from '@/helpers/eventBus'
import hasPermission from '@/helpers/permissions'
import { getColNumber } from '@/helpers/utils'
import { useUsersStore } from '@/stores/user'
import { EUserEvent } from '@/types/eventBus.d'
import { EPermission } from '@/types/permission.d'
import { IUserDetails, IUserRights } from '@/types/user.d'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'
import FormLine from '@/components/form/FormLine.vue'
import ValidationField from '@/components/form/ValidationField.vue'

const { t } = useI18n()
const usersStore = useUsersStore()

const props = defineProps<{
  userDetails: IUserDetails,
  accountUuid: string
}>()

const userRights = ref<IUserRights | null>(null)
const editableRights = ref<IUserRights | null>(null)

const loading = ref(false)
const isEditing = ref(false)

onMounted(async () => {
  bus.on(EUserEvent.SetMainOwner, getUserRights)
  bus.on(EUserEvent.SetFullAccess, getUserRights)
  bus.on(EUserEvent.RemoveFullAccess, getUserRights)

  await getUserRights()
})

onUnmounted(() => {
  bus.off(EUserEvent.SetMainOwner, getUserRights)
  bus.off(EUserEvent.SetFullAccess, getUserRights)
  bus.off(EUserEvent.RemoveFullAccess, getUserRights)
})

const rightsDetails = computed(() => {
  return [
    { right: 'operations', title: t('general.operations') },
    { right: 'accounting', title: t('general.accounting') },
    { right: 'cards', title: t('general.cards') },
    { right: 'card_virtual_request_user', title: t('general.card_virtual_request_user'), isHidden: rights => rights.cards === true },
    { right: 'statements', title: t('general.statements') },
    { right: 'shared_access', title: t('general.shared_access') }
  ].filter(item => Object.prototype.hasOwnProperty.call(userRights.value || {}, item.right))
})

const editableRightsDetails = computed(() => {
  return rightsDetails.value
    .filter(right => !['shared_access'].includes(right.right))
})

const validationSchema = computed(() => {
  return yup.object().shape({
    ...Object.fromEntries(editableRightsDetails.value.map(right => [right.right, yup.boolean()]))
  })
})

async function getUserRights () {
  loading.value = true
  userRights.value = props.accountUuid
    ? await usersStore.getUserRights(props.userDetails.uuid, props.accountUuid)
    : props.userDetails.rights
  loading.value = false
}

function checkRight (right: string): boolean {
  return userRights.value ? userRights.value[right] === true : false
}

function getIconProps (right: string): { name: ECoreIconBoList, size: TCoreIconSize, theme: ECoreIconTheme } {
  const hasRight = checkRight(right)
  return {
    name: hasRight ? ECoreIconBoList.BoCheckCircle : ECoreIconBoList.BoXCircle,
    size: 16,
    theme: hasRight ? ECoreIconTheme.Success : ECoreIconTheme.Danger
  }
}

function startEditing () {
  if (checkRight('owner')) return
  if (userRights.value) editableRights.value = cloneDeep(userRights.value)
  isEditing.value = true
}

function cancelForm () {
  isEditing.value = false
}

async function onSubmit () {
  if (!editableRights.value) return
  const updatedRights = await usersStore.updateUserRights(props.userDetails.uuid, props.accountUuid, editableRights.value)
  if (updatedRights) {
    userRights.value = updatedRights
    isEditing.value = false
  }
}

const ownerText = computed(() => {
  return props.userDetails.is_main_owner ? t('general.main_owner_rights') : t('general.owner_rights')
})

</script>

<style lang="stylus" scoped>
.user-rights
  &__owner-alert
    margin-bottom 2rem
</style>
