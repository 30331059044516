import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'
import { defineStore } from 'pinia'

import axiosClient from '@/api'
import bus from '@/helpers/eventBus'
import { showToastSuccess } from '@/helpers/utils/notification'
import i18n from '@/locales'
import { EScaEvent, EUserEvent } from '@/types/eventBus.d'
import { ISca } from '@/types/sca.d'
import { IUser, IUserDetails, IUserLight, IUserParams, IUserRights } from '@/types/user.d'

const { t } = i18n.global

export const useUsersStore = defineStore('user', {
  actions: {
    async getUsers (params: IUserParams): Promise<{ data: IUser[], headers: RawAxiosResponseHeaders | AxiosResponseHeaders, error: boolean }> {
      try {
        const response = await axiosClient.get('/users', { params })

        return { data: response.data, headers: response.headers, error: false }
      } catch (error) {
        console.error(error)
        return { data: [], headers: {}, error: true }
      }
    },

    async getUsersLight (account_uuid: string): Promise<IUserLight[]> {
      try {
        const { data } = await axiosClient.get('/users?scenario=light', { params: { account_uuid } })
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async getUserDetails (uuid: string, account_uuid: string): Promise<IUserDetails | null> {
      try {
        const { data } = await axiosClient.get(`/users/${uuid}`, { params: { account_uuid } })
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getUserRights (uuid: string, account_uuid: string): Promise<IUserRights | null> {
      try {
        const { data } = await axiosClient.get(`/users/${uuid}/rights`, { params: { account_uuid } })
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async updateUserRights (uuid: string, account_uuid: string, payload: IUserRights): Promise<IUserRights | null> {
      try {
        const { data } = await axiosClient.put(`/users/${uuid}/rights`, payload, { params: { account_uuid } })
        showToastSuccess(t('success.update_user_rights'))
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getUserSca (uuid: string): Promise<ISca[]> {
      try {
        const { data } = await axiosClient.get('/users/sca', { params: { user_uuid: uuid } })
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async deleteUserSca (uuid: string, user_uuid: string) {
      try {
        await axiosClient.delete(`/users/sca/${uuid}`, { params: { user_uuid } })
        bus.emit(EScaEvent.Revoke, uuid)
        showToastSuccess(t('success.sca_revoke'))
      } catch (error) {
        console.error(error)
      }
    },

    async setUserMainOwner (uuid: string, account_uuid: string): Promise<IUserDetails | null> {
      try {
        const { data } = await axiosClient.patch(`/users/${uuid}/set-main-owner?account_uuid=${account_uuid}`)
        bus.emit(EUserEvent.SetMainOwner, data)
        showToastSuccess(t('success.set_main_owner'))
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async setUserFullAccess (uuid: string, account_uuid: string): Promise<IUserDetails | null> {
      try {
        const { data } = await axiosClient.patch(`/users/${uuid}/set-full-access?account_uuid=${account_uuid}`)
        bus.emit(EUserEvent.SetFullAccess, data)
        showToastSuccess(t('success.set_full_access'))
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async removeUserFullAccess (uuid: string, account_uuid: string): Promise<IUserDetails | null> {
      try {
        const { data } = await axiosClient.patch(`/users/${uuid}/remove-full-access?account_uuid=${account_uuid}`)
        bus.emit(EUserEvent.RemoveFullAccess, data)
        showToastSuccess(t('success.remove_full_access'))
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async resetPassword (uuid: string): Promise<string | null> {
      try {
        const { data } = await axiosClient.patch(`/users/${uuid}/password-recovery`)
        showToastSuccess(data.message)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async setPassword (uuid: string, account_uuid: string): Promise<string | null> {
      try {
        const { data } = await axiosClient.patch(`/users/${uuid}/account-enrollment?account_uuid=${account_uuid}`)
        showToastSuccess(data.message)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    }
  }
})
