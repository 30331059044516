<template>
  <sidepanel-block :title="t('general.modification_details')"
                   collapsable>
    <template #content>
      <template v-if="wasTemporaryLimitEnabled">
        <sidepanel-line :label="t('account.setting.limit.default_monthly_limit')"
                        :data="formatAmount(props.accountTemporaryLimitSettingsUpdate.before.transaction_amount_monthly_limit, currency)"
                        strikethrough />
        <sidepanel-line :label="t('account.setting.temporary_limit.new_limit')"
                        :data="formatAmount(props.accountTemporaryLimitSettingsUpdate.after.transaction_amount_temporary.limit ?? 0, currency)" />
        <sidepanel-line :label="t('account.setting.temporary_limit.period')"
                        :data-list="getPeriod(props.accountTemporaryLimitSettingsUpdate, EState.After)" />
      </template>
      <template v-else-if="wasTemporaryLimitDisabled">
        <sidepanel-line :label="t('account.setting.temporary_limit.current_limit')"
                        :data="formatAmount(props.accountTemporaryLimitSettingsUpdate.before.transaction_amount_temporary.limit ?? 0, currency)"
                        strikethrough />
        <sidepanel-line :label="t('account.setting.limit.default_monthly_limit')"
                        :data="formatAmount(props.accountTemporaryLimitSettingsUpdate.after.transaction_amount_monthly_limit, currency)" />
      </template>
      <template v-else>
        <sidepanel-line :label="t('account.setting.temporary_limit.current_limit')"
                        :data="formatAmount(props.accountTemporaryLimitSettingsUpdate.before.transaction_amount_temporary.limit ?? 0, currency)"
                        strikethrough />
        <sidepanel-line :label="t('account.setting.temporary_limit.new_limit')"
                        :data="formatAmount(props.accountTemporaryLimitSettingsUpdate.after.transaction_amount_temporary.limit ?? 0, currency)" />
        <sidepanel-line :label="t('account.setting.temporary_limit.current_period')"
                        :data-list="getPeriod(props.accountTemporaryLimitSettingsUpdate, EState.Before)"
                        strikethrough />
        <sidepanel-line :label="t('account.setting.temporary_limit.new_period')"
                        :data-list="getPeriod(props.accountTemporaryLimitSettingsUpdate, EState.After)" />
      </template>
    </template>
  </sidepanel-block>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'

import { formatDateText } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { IAccountTemporaryLimit } from '@/types/account.d'

import SidepanelBlock from '@/components/sidepanel/SidepanelBlock.vue'
import SidepanelLine from '@/components/sidepanel/SidepanelLine.vue'

const currency = { iso: 'EUR', label: 'Euro', fraction_digits: 2, flag_url: 'none' }

enum EState {
  Before = 'before',
  After = 'after'
}

type TAccountLimitSettingsUpdate = {
  before: {
    transaction_amount_temporary: IAccountTemporaryLimit
    transaction_amount_monthly_limit: number
  }
  after: {
    transaction_amount_temporary: IAccountTemporaryLimit
    transaction_amount_monthly_limit: number
  }
}

const { t } = useI18n()

const props = defineProps<{
  accountTemporaryLimitSettingsUpdate: TAccountLimitSettingsUpdate
}>()

const wasTemporaryLimitEnabled = computed(() => {
  return !props.accountTemporaryLimitSettingsUpdate.before.transaction_amount_temporary.enabled &&
          props.accountTemporaryLimitSettingsUpdate.after.transaction_amount_temporary.enabled
})

const wasTemporaryLimitDisabled = computed(() => {
  return props.accountTemporaryLimitSettingsUpdate.before.transaction_amount_temporary.enabled &&
        !props.accountTemporaryLimitSettingsUpdate.after.transaction_amount_temporary.enabled
})

function getPeriod (setting: TAccountLimitSettingsUpdate, state: EState) {
  const data = [
    {
      label: '',
      value: `${t('general.from')} ${formatDateText(dayjs(setting[state].transaction_amount_temporary.start_date))}`
    },
    {
      label: '',
      value: `${t('general.to')} ${formatDateText(dayjs(setting[state].transaction_amount_temporary.end_date))}`
    }
  ]
  return data
}
</script>
