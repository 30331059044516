<template>
  <core-modal active
              :title="t('modal.card_unlock.title')"
              :closable-button="false"
              data-cy="card-detail.modal-action.container">
    <template #body>
      <p class="pre-line">
        {{ content }}
      </p>
      <core-dropdown v-model="reason"
                     :items="reasonList"
                     :placeholder="t('placeholder.reason')"
                     :size="ECoreDropdownSize.Small"
                     min-width="100%"
                     :disabled="loading"
                     :list-loading="loadingReasons"
                     data-cy="card-detail.modal-action.reason-dropdown"
                     class="mt-2" />
    </template>
    <template #footer>
      <core-button :theme="ECoreButtonTheme.Transparent"
                   :appearance="ECoreButtonAppearance.Filled"
                   :text="t('action.cancel')"
                   @click="closeModal" />
      <core-button :text="t('action.card_unlock')"
                   :loading="loading"
                   :disabled="!reason || loading"
                   data-cy="card-detail.modal-action.button"
                   @click="onSubmit" />
    </template>
  </core-modal>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreButton,
  CoreDropdown,
  CoreModal,
  ECoreButtonAppearance,
  ECoreButtonTheme, ECoreDropdownSize,
  TDropdownItem
} from '@common/core-ui'

import bus from '@/helpers/eventBus'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'
import { ICardDetails } from '@/types/card.d'
import { ECardEvent } from '@/types/eventBus.d'

const { t } = useI18n()

const props = defineProps<{
  card: ICardDetails
}>()

const appStore = useAppStore()
const cardStore = useCardStore()

const reasonList = ref<TDropdownItem[]>([])
const reason = ref<string | null>(null)
const loading = ref(false)
const loadingReasons = ref(false)

onMounted(async () => {
  await getReasons()
  bus.on(ECardEvent.Unlock, closeModal)
})

onUnmounted(() => {
  bus.off(ECardEvent.Unlock, closeModal)
})

const content = computed(() => {
  const fullName = `${props.card.first_name} ${props.card.last_name}`
  return t('modal.card_unlock.content', { name: fullName, digits: props.card.last_digits })
})

async function getReasons () {
  loadingReasons.value = true
  const response = await cardStore.getUnlockReasons()
  reasonList.value = response.map((item, index) => { return { id: index, label: item.label, value: item.value } })
  loadingReasons.value = false
}

function closeModal () {
  appStore.closeModal()
}

async function onSubmit () {
  if (!reason.value) return

  loading.value = true
  await cardStore.unlockCard(props.card.uuid, reason.value)
  loading.value = false
}
</script>
