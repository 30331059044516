<template>
  <sidepanel-block :title="t('general.reject_reason')"
                   collapsable>
    <template #content>
      <span class="reject_reason__comment">{{ comment }}</span>
    </template>
  </sidepanel-block>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import SidepanelBlock from '@/components/sidepanel/SidepanelBlock.vue'

const { t } = useI18n()

defineProps<{
  comment: string
}>()
</script>

<style scoped lang="stylus">
.reject_reason
  &__comment
    color $color-gray-800
    font-size 1.55rem
</style>
