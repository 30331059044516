<template>
  <div class="card-information"
       data-cy="card-detail.information.view">
    <core-alert v-if="hasBankValidationRequest"
                :icon-name="ECoreIcon.BoInfo"
                :theme="ECoreAlertTheme.Info"
                :text="t('alert.card_bvr')">
      <template #actions>
        <router-link class="core-link--theme-info core-link--underline core-link--bold"
                     data-cy="card-detail.information.bvr.action"
                     :to="{ name: 'to-validate', query: { mode: 'bank' } }">
          {{ t('action.see_details') }}
        </router-link>
      </template>
    </core-alert>
    <block-information :card-details="cardDetails" />
    <div v-if="hasCardSettingReadPermission && limitSetting"
         class="card-information__limits">
      <template v-if="isRecurringVirtualCard">
        <recurring-virtual-card-payment-block :card-limit-setting="limitSetting" />
      </template>
      <template v-else-if="isOneTimeVirtualCard">
        <one-time-virtual-card-payment-block :card-limit-setting="limitSetting" />
      </template>
      <template v-else>
        <block-payments :card-limit-setting="limitSetting" />
        <block-withdrawals :card-limit-setting="limitSetting" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreAlert, ECoreAlertTheme, ECoreIcon } from '@common/core-ui'

import hasPermission from '@/helpers/permissions'
import { useCardStore } from '@/stores/card'
import { ICardDetails, ICardLimitSetting, isOneTimeVirtualCardType, isRecurringVirtualCardType } from '@/types/card.d'
import { EPermission } from '@/types/permission.d'

import BlockInformation from '@/pages/cards/card-id/tabs/information/blocks/BlockInformation.vue'
import BlockPayments from '@/pages/cards/card-id/tabs/information/blocks/BlockPayments.vue'
import BlockWithdrawals from '@/pages/cards/card-id/tabs/information/blocks/BlockWithdrawals.vue'
import OneTimeVirtualCardPaymentBlock
  from '@/pages/cards/card-id/tabs/information/blocks/OneTimeVirtualCardPaymentBlock.vue'
import RecurringVirtualCardPaymentBlock
  from '@/pages/cards/card-id/tabs/information/blocks/RecurringVirtualCardPaymentBlock.vue'

const cardStore = useCardStore()
const { t } = useI18n()

const props = defineProps<{
  cardDetails: ICardDetails
  hasBankValidationRequest: boolean
}>()

const limitSetting = ref<ICardLimitSetting | null>(null)

onMounted(async () => {
  const { data } = await cardStore.getCardLimitSetting(props.cardDetails.uuid)
  limitSetting.value = data
})

const hasCardSettingReadPermission = computed(() => {
  return hasPermission(EPermission.CardSettingRead)
})

const isRecurringVirtualCard = computed(() => {
  return isRecurringVirtualCardType(props.cardDetails.type)
})

const isOneTimeVirtualCard = computed(() => {
  return isOneTimeVirtualCardType(props.cardDetails.type)
})
</script>
