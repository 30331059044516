import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'
import { defineStore } from 'pinia'

import axiosClient from '@/api'
import bus from '@/helpers/eventBus'
import { parsePagination } from '@/helpers/pagination'
import { showToastSuccess } from '@/helpers/utils/notification'
import i18n from '@/locales'
import {
  EBankValidationRequestMode, EBankValidationRequestStatus,
  IBankValidationRequest,
  IBankValidationRequestDetails,
  IBankValidationRequestParams
} from '@/types/bank-validation-request.d'
import { EBankValidationRequestEvent } from '@/types/eventBus.d'

const { t } = i18n.global

interface State {
  count: Record<EBankValidationRequestMode, number>
}

export const useBankValidationRequestStore = defineStore('bank-validation-request', {
  state: (): State => {
    return {
      count: {
        [EBankValidationRequestMode.Client]: 0,
        [EBankValidationRequestMode.Bank]: 0
      }
    }
  },

  getters: {
    toValidateCount: state => state.count[EBankValidationRequestMode.Client] + state.count[EBankValidationRequestMode.Bank]
  },

  actions: {
    async getBankValidationRequests (params: IBankValidationRequestParams): Promise<{ data: IBankValidationRequest[], headers: RawAxiosResponseHeaders | AxiosResponseHeaders, error: boolean }> {
      try {
        const response = await axiosClient.get('/bank-validation-requests', { params })

        return { data: response.data, headers: response.headers, error: false }
      } catch (error) {
        console.error(error)
        return { data: [], headers: {}, error: true }
      }
    },

    async getBankValidationRequestsCount () {
      try {
        for (const mode of [EBankValidationRequestMode.Client, EBankValidationRequestMode.Bank]) {
          const { headers } = await axiosClient.head('/bank-validation-requests', { params: { mode, status: [EBankValidationRequestStatus.ToValidate] } })
          this.count[mode] = parsePagination(headers).totalItems
        }
      } catch (error) {
        console.error(error)
      }
    },

    async getBankValidationRequestDetails (uuid: string): Promise<{ data: IBankValidationRequestDetails | null, error: boolean }> {
      try {
        const { data } = await axiosClient.get(`/bank-validation-requests/${uuid}`)

        return { data, error: false }
      } catch (error) {
        console.error(error)
        return { data: null, error }
      }
    },

    async getBankValidationRequestTypes (): Promise<{ data: { label: string, value: string }[] | null, error: boolean }> {
      try {
        const { data } = await axiosClient.get('/bank-validation-requests/types')

        return { data, error: false }
      } catch (error) {
        console.error(error)
        return { data: null, error }
      }
    },

    async cancelBankValidationRequest (uuid: string) {
      try {
        const { data } = await axiosClient.patch(`/bank-validation-requests/${uuid}/cancel`)

        bus.emit(EBankValidationRequestEvent.Cancel, data)
        showToastSuccess(t('success.bank_validation_request_cancel'))
        return data
      } catch (error) {
        console.error(error)
      }
    },

    async validateBankValidationRequest (uuid: string) {
      try {
        const { data } = await axiosClient.patch(`/bank-validation-requests/${uuid}/validate`)

        bus.emit(EBankValidationRequestEvent.Validate, data)
        showToastSuccess(t('success.bank_validation_request_validate'))
        return data
      } catch (error) {
        console.error(error)
      }
    },

    async rejectBankValidationRequest (uuid: string, comment: string) {
      try {
        const { data } = await axiosClient.patch(`/bank-validation-requests/${uuid}/reject`, { comment })

        bus.emit(EBankValidationRequestEvent.Reject, data)
        showToastSuccess(t('success.bank_validation_request_reject'))
        return data
      } catch (error) {
        console.error(error)
      }
    }
  }
})
