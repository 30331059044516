<template>
  <details-block data-cy="account-detail.information.limits-block"
                 :title="t('general.expenses_and_limits')"
                 :columns="1">
    <template #col-1>
      <details-line v-if="hasTemporaryLimit"
                    data-cy="account-detail.information.limits-block.default-outstanding"
                    :title="t('general.default_outstanding')"
                    :data="[formatAmount(settings.limit, currency)]" />
      <details-line :title="currentMonthLimit.label"
                    data-cy="account-detail.information.limits-block.current-month-limit"
                    :data="[formatAmount(settings.consumed, currency)]"
                    :total="[currentMonthLimit.data]"
                    :exceeded="balanceExceeded" />
      <details-line :title="hasTemporaryLimit ? t('general.temporary_outstanding_balance') : t('general.outstanding_balance')"
                    data-cy="account-detail.information.limits-block.remaining-outstanding"
                    :data="[remaining]" />
      <details-line :title="t('general.alert_threshold')"
                    data-cy="account-detail.information.limits-block.alert-threshold"
                    :data="[alertThreshold]" />
      <details-line>
        <template #content>
          <component-gauge v-if="accountDetails.settings && settings.limit"
                           class="block-details__body__row__gauge"
                           :value="settings.consumed"
                           :limit="limit"
                           :excess="settings.excess"
                           :alert="settings.alert" />
        </template>
      </details-line>
      <template v-if="currentMonthExcess && isMonthExcessVisible">
        <details-line :title="t('general.exceed')"
                      data-cy="account-detail.information.limits-block.excess"
                      :data="[formatAmount(currentMonthExcess.current, currency)]"
                      :total="currentMonthExcess.limit ? [formatAmount(currentMonthExcess.limit, currency)] : undefined" />
      </template>
      <template v-else>
        <details-line />
      </template>
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import hasPermission from '@/helpers/permissions'
import { formatAmount } from '@/helpers/utils/number'
import { IAccountDetails } from '@/types/account.d'
import { ICurrency } from '@/types/currency.d'
import { EPermission } from '@/types/permission.d'

import ComponentGauge from '@/components/component-gauge/ComponentGauge.vue'
import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const { t } = useI18n()

const props = defineProps<{
  accountDetails: IAccountDetails
  hasTemporaryLimit: boolean
}>()

const settings = computed(() => {
  return {
    limit: props.accountDetails.settings?.transaction_amount_monthly_limit ?? 0,
    consumed: props.accountDetails.settings?.transaction_amount_monthly_consumed ?? 0,
    alert: props.accountDetails.settings?.transaction_amount_monthly_alert_threshold_percentage,
    excess: props.accountDetails.settings?.transaction_amount_monthly_excess_allowed_percentage ?? 0,
    temporary: props.accountDetails.settings?.transaction_amount_temporary
  }
})

const balanceExceeded = computed(() => {
  if (!settings.value.limit) {
    return false
  }
  return settings.value.consumed > settings.value.limit
})

const currency = computed<ICurrency>(() => {
  return { iso: 'EUR', label: 'Euro', fraction_digits: 2, flag_url: 'none' }
})

const limit = computed(() => {
  return props.hasTemporaryLimit ? settings.value.temporary?.limit : settings.value.limit
})

const remaining = computed(() => {
  if (!limit.value) {
    return '-'
  }

  const remaining = limit.value - settings.value.consumed
  if (remaining < 0) {
    return formatAmount(0, currency.value)
  }
  return formatAmount(remaining, currency.value)
})

const alertThreshold = computed(() => {
  if (!settings.value.alert || !limit.value) {
    return '-'
  }
  const percentage = settings.value.alert
  const value = limit.value * (percentage / 100)
  return `${percentage} % (${formatAmount(value, currency.value)})`
})

const currentMonthLimit = computed(() => {
  const label = props.hasTemporaryLimit
    ? t('general.temporary_outstanding_current_month')
    : t('general.outstanding_current_month')

  const data = limit.value ? formatAmount(limit.value, currency.value) : undefined

  return {
    label,
    data
  }
})

const currentMonthExcess = computed(() => {
  if (!limit.value || (!settings.value.excess && settings.value.consumed <= limit.value)) {
    return null
  }

  const current = settings.value.consumed - limit.value
  const excessLimit = limit.value * (settings.value.excess / 100)

  return {
    current: current > 0 ? current : 0,
    limit: excessLimit
  }
})

const isMonthExcessVisible = computed(() => {
  return hasPermission(EPermission.AccountTransactionAmountMonthlyExcessAllowedRead)
})
</script>
