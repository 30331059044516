<template>
  <validation-form ref="settingsLimits"
                   v-slot="{ meta, isSubmitting }"
                   :validation-schema="validationSchema"
                   data-cy="account-settings.limits.form"
                   class="account-settings-limit"
                   @submit="onSubmit">
    <core-alert v-if="hasBankValidationRequest"
                :icon-name="ECoreIcon.BoInfo"
                :theme="ECoreAlertTheme.Info"
                :text="t('alert.account_limits_bvr')">
      <template #actions>
        <router-link class="core-link--theme-info core-link--underline core-link--bold"
                     data-cy="card-detail.information.bvr.action"
                     :to="{ name: 'to-validate', query: { mode: 'bank' } }">
          {{ t('action.see_details') }}
        </router-link>
      </template>
    </core-alert>
    <details-block :header-size="EDefaultSize.Large"
                   :loading="loading"
                   :title="t('account.setting.limit.title')"
                   :columns="1">
      <template #col-1>
        <form-line :title="t('account.setting.limit.monthly_limit')">
          <template #input>
            <input-number v-model:model-value="settingsLimits.transaction_amount_monthly_limit"
                          name="transaction_amount_monthly_limit"
                          data-cy="account-settings.limits.monthly-limit.input"
                          local-string
                          :placeholder="t('placeholder.amount')"
                          :size="ECoreInputSize.Small">
              <template #append>
                <span class="account-settings-limit__currency">€</span>
              </template>
            </input-number>
          </template>
        </form-line>
        <form-line :title="t('account.setting.limit.monthly_alert_threshold')">
          <template #input-header>
            <validation-field v-slot="field"
                              v-model:model-value="settingsLimits.transaction_amount_monthly_alert_threshold_percentage_enabled"
                              name="transaction_amount_monthly_alert_threshold_percentage_enabled">
              <core-toggle-switch v-bind="field"
                                  data-cy="account-settings.limits.alert-threshold.toggle"
                                  :label="t('general.rate_range', { min: 50, max: 100 })" />
            </validation-field>
          </template>
          <template v-if="settingsLimits.transaction_amount_monthly_alert_threshold_percentage_enabled"
                    #input>
            <input-number v-model:model-value="settingsLimits.transaction_amount_monthly_alert_threshold_percentage"
                          name="transaction_amount_monthly_alert_threshold_percentage"
                          local-string
                          data-cy="account-settings.limits.alert-threshold.input"
                          :placeholder="t('placeholder.rate')"
                          :disabled="!settingsLimits.transaction_amount_monthly_alert_threshold_percentage_enabled"
                          :size="ECoreInputSize.Small">
              <template #append>
                <span class="account-settings-limit__currency">%</span>
              </template>
            </input-number>
          </template>
          <template v-if="settingsLimits.transaction_amount_monthly_alert_threshold_percentage_enabled"
                    #input-addon>
            <div class="account-settings-limit__input-addon">
              <core-icon :name="ECoreIconBoList.BoInfo"
                         :theme="ECoreIconTheme.GrayMid"
                         :size="16" />
              <span>{{ getPercentageValue(settingsLimits.transaction_amount_monthly_alert_threshold_percentage) }}</span>
            </div>
          </template>
        </form-line>
        <form-line v-if="isExcessAllowed(settingsLimits) && isExcessAllowedEditable"
                   :title="t('account.setting.limit.monthly_excess_allowed')">
          <template #input-header>
            <validation-field v-slot="field"
                              v-model:model-value="settingsLimits.transaction_amount_monthly_excess_allowed_percentage_enabled"
                              name="transaction_amount_monthly_excess_allowed_percentage_enabled">
              <core-toggle-switch v-bind="field"
                                  data-cy="account-settings.limits.excess-allowed.toggle"
                                  :label="t('general.rate_range', { min: 0, max: 50 })" />
            </validation-field>
          </template>
          <template v-if="settingsLimits.transaction_amount_monthly_excess_allowed_percentage_enabled"
                    #input>
            <input-number v-model:model-value="settingsLimits.transaction_amount_monthly_excess_allowed_percentage"
                          name="transaction_amount_monthly_excess_allowed_percentage"
                          local-string
                          data-cy="account-settings.limits.excess-allowed.input"
                          :placeholder="t('placeholder.rate')"
                          :size="ECoreInputSize.Small">
              <template #append>
                <span class="account-settings-limit__currency">%</span>
              </template>
            </input-number>
          </template>
          <template v-if="settingsLimits.transaction_amount_monthly_excess_allowed_percentage_enabled"
                    #input-addon>
            <div class="account-settings-limit__input-addon">
              <core-icon :name="ECoreIconBoList.BoInfo"
                         :theme="ECoreIconTheme.GrayMid"
                         :size="16" />
              <span>{{ getPercentageValue(settingsLimits.transaction_amount_monthly_excess_allowed_percentage) }}</span>
            </div>
          </template>
        </form-line>
      </template>
      <template #footer-right>
        <core-button :text="t('action.cancel')"
                     :theme="ECoreButtonTheme.Transparent"
                     class="mr-1"
                     @click="cancelForm" />
        <core-button :text="t('action.validate')"
                     data-cy="account-settings.limits.submit"
                     type="submit"
                     :disabled="!meta.valid || isSubmitting"
                     :theme="ECoreButtonTheme.Primary"
                     :loading="isSubmitting" />
      </template>
    </details-block>
  </validation-form>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import {
  CoreAlert,
  CoreButton,
  CoreIcon,
  CoreToggleSwitch,
  ECoreAlertTheme,
  ECoreButtonTheme,
  ECoreIcon,
  ECoreIconBoList,
  ECoreIconTheme,
  ECoreInputSize
} from '@common/core-ui'
import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'
import { cloneDeep } from 'lodash'
import * as yup from 'yup'

import bus from '@/helpers/eventBus'
import hasPermission from '@/helpers/permissions'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { IAccountDetails, IAccountSettings, isExcessAllowed } from '@/types/account.d'
import { EBankValidationRequestType } from '@/types/bank-validation-request.d'
import { ICurrency } from '@/types/currency.d'
import { EBankValidationRequestEvent } from '@/types/eventBus.d'
import { EResponseHeader } from '@/types/headers.d'
import { EPermission } from '@/types/permission.d'
import { EDefaultSize } from '@/types/size.d'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import FormLine from '@/components/form/FormLine.vue'
import ValidationField from '@/components/form/ValidationField.vue'
import InputNumber from '@/components/inputs/InputNumber.vue'
import ModalOverwriteRequest from '@/components/modal/ModalOverwriteRequest.vue'

const router = useRouter()
const accountStore = useAccountStore()
const appStore = useAppStore()
const { t } = useI18n()

const loading = ref(true)
const responseHeaders = ref<RawAxiosResponseHeaders | AxiosResponseHeaders | null>(null)

const props = defineProps<{
  accountUuid: string
  accountDetails: IAccountDetails
}>()

const settingsLimits = ref<IAccountSettings>({
  transaction_amount_monthly_limit: 0,
  transaction_amount_monthly_alert_threshold_percentage_enabled: false,
  transaction_amount_monthly_alert_threshold_percentage: null,
  transaction_amount_monthly_excess_allowed_percentage_enabled: false,
  transaction_amount_monthly_excess_allowed_percentage: 0
})

onMounted(async () => {
  const { data, headers } = await accountStore.getLimitSettings(props.accountUuid)
  responseHeaders.value = headers
  if (data) {
    const filteredSettings = cloneDeep(data)
    delete filteredSettings.transaction_amount_monthly_consumed

    settingsLimits.value = filteredSettings
  }
  bus.on(EBankValidationRequestEvent.Overwrite, saveLimitSetting)
  loading.value = false
})

onUnmounted(() => {
  bus.off(EBankValidationRequestEvent.Overwrite, saveLimitSetting)
})

const validationSchema = computed(() => {
  return yup.object({
    transaction_amount_monthly_limit: yup.number().required(),
    transaction_amount_monthly_alert_threshold_percentage_enabled: yup.boolean(),
    transaction_amount_monthly_alert_threshold_percentage: yup
      .number()
      .min(50)
      .max(100)
      .when('transaction_amount_monthly_alert_threshold_percentage_enabled', ([transaction_amount_monthly_alert_threshold_percentage_enabled], schema) => {
        return transaction_amount_monthly_alert_threshold_percentage_enabled ? schema.required() : schema.nullable()
      }),
    transaction_amount_monthly_excess_allowed_percentage: yup
      .number()
      .min(0)
      .max(50)
      .when('transaction_amount_monthly_excess_allowed_percentage_enabled', ([transaction_amount_monthly_excess_allowed_percentage_enabled], schema) => {
        return transaction_amount_monthly_excess_allowed_percentage_enabled ? schema.required() : schema.nullable()
      })
  })
})

const currency = computed<ICurrency>(() => {
  return { iso: 'EUR', label: 'Euro', fraction_digits: 2, flag_url: 'none' }
})

const isExcessAllowedEditable = computed(() => {
  return hasPermission(EPermission.AccountTransactionAmountMonthlyExcessAllowedUpdate)
})

const hasBankValidationRequest = computed(() => {
  return !!responseHeaders.value?.[EResponseHeader.BankValidationRequest]
})

function getPercentageValue (percentage: number | null) {
  const limit = settingsLimits.value.transaction_amount_monthly_limit
  let value = 0

  if (limit && percentage) value = (limit * percentage) / 100

  return `${t('general.ie')} ${formatAmount(value, currency.value, { fixedFractionDigits: 2 })}`
}

async function onSubmit () {
  if (hasBankValidationRequest.value) {
    appStore.showModal(ModalOverwriteRequest, { requestType: EBankValidationRequestType.UpdateAccountLimit })
  } else {
    await saveLimitSetting()
  }
}

async function saveLimitSetting () {
  const payload = cloneDeep(settingsLimits.value)
  if (payload.transaction_amount_monthly_alert_threshold_percentage_enabled === false) payload.transaction_amount_monthly_alert_threshold_percentage = 0
  if (payload.transaction_amount_monthly_excess_allowed_percentage_enabled === false) payload.transaction_amount_monthly_excess_allowed_percentage = 0

  const { data, headers } = await accountStore.updateLimitSettings(props.accountUuid, payload)
  responseHeaders.value = headers
  settingsLimits.value = data
}

function cancelForm () {
  router.go(-1)
}
</script>

<style scoped lang="stylus">
.account-settings-limit
  display flex
  flex-direction column
  gap 2rem

  &__input-addon
    display flex
    align-items center
    gap .8rem

    span
      font-size 1.55rem
      color $color-gray-600

  &__currency
    color $color-gray-600
</style>
