import { ECoreBannerTheme } from '@common/core-ui'
import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import NProgress from 'nprogress'

import { getFlatErrorsArray } from '@/helpers/utils'
import { i18n } from '@/locales'
import router from '@/router'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { ERequestHeader, EResponseHeader } from '@/types/headers.d'
dayjs.extend(utc)

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL || '/api'
})

const NProgressInstance = NProgress.configure({ showSpinner: false, speed: 200, trickleSpeed: 100 })

const removeAbortController = response => {
  const apiStore = useApiStore()

  if (response.config) {
    apiStore.removeAbortController(response.config.signal)
  }
}

let startTime = 0
let endTime = 0

axiosClient.interceptors.request.use(async config => {
  const apiStore = useApiStore()
  const authStore = useAuthStore()

  config.responseType ||= 'json'
  apiStore.incrementOpenedRequests()

  if (import.meta.env.VITE_CLIENT_BRAND) {
    config.headers[ERequestHeader.ClientBrand] = import.meta.env.VITE_CLIENT_BRAND
  }

  config.headers[ERequestHeader.BackOfficeVersion] = import.meta.env.PROJECT_VERSION
  config.headers[ERequestHeader.AcceptLanguage] = i18n.global.locale.value

  if (!NProgressInstance.isStarted() && !apiStore.hideProgressBar) {
    NProgressInstance.start()
  }
  startTime = dayjs().valueOf()

  if (['get', 'head'].includes(config.method) && !config.signal && config.responseType === 'json') {
    config.signal = apiStore.addAbortController().signal
  }

  for (const key in config.params) {
    if (Object.prototype.hasOwnProperty.call(config.params, key) && (config.params[key] === '' || config.params[key] === null)) {
      delete config.params[key]
    }
  }
  if (config.params?.search) { config.params.search = config.params.search.trim() }

  if (authStore.boUser.token !== null && !config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${authStore.boUser.token}`
  }

  config.headers[ERequestHeader.ClientDateTime] = dayjs().format()

  if (import.meta.env.VITE_API_PROXY_URL && config.url.startsWith(import.meta.env.VITE_API_PROXY_URL)) {
    // if we are in proxy mode, ensure api url are proxy
    config.url = config.url.replace(import.meta.env.VITE_API_PROXY_URL, '')
  }

  apiStore.resetError()
  apiStore.setLoading(true)

  return config
}, error => {
  const apiStore = useApiStore()

  NProgressInstance.done()
  apiStore.setLoading(false)
  return Promise.reject(error)
})

axiosClient.interceptors.response.use(response => {
  const apiStore = useApiStore()
  const authStore = useAuthStore()

  apiStore.decrementOpenedRequests()
  removeAbortController(response)

  if (response.headers[EResponseHeader.AuthorizationToken]) {
    authStore.replaceToken(response.headers[EResponseHeader.AuthorizationToken])
  }

  endTime = dayjs().valueOf()
  const requestTime = endTime - startTime
  if (response.headers.date) {
    const deltaTime = (dayjs.utc(response.headers.date).valueOf() + requestTime - dayjs().valueOf()) / 1000
    apiStore.setDeltaTime(deltaTime)
  }

  if (!apiStore.openedRequests) {
    apiStore.setLoading(false)
    if (!apiStore.hideProgressBar && response.status) {
      NProgressInstance.done()
    }
  }
  return response
}, async error => {
  const apiStore = useApiStore()
  const appStore = useAppStore()

  apiStore.decrementOpenedRequests()
  removeAbortController(error)

  if (!apiStore.openedRequests) {
    apiStore.setLoading(false)

    if (!apiStore.hideProgressBar && error.response && error.response.status) {
      NProgressInstance.done()
    }
  }

  if (!error.response || error.code === 'ERR_NETWORK' || error.code === 'ERR_CANCELED') {
    if (error.code !== 'ERR_CANCELED') {
      appStore.showBannerError(i18n.global.t('error.default'))
    }
    NProgressInstance.done()
    return Promise.reject(error)
  }

  if (error.response.status === 401) {
    appStore.closeSidePanel()

    const message = (router.currentRoute.value.name === 'login') ? i18n.global.t('error.login') : i18n.global.t('error.logout')

    if (!error.config.doNotShowBanner) {
      appStore.showBanner({ auto_dismiss: true, closable: true, message: error.response.data.message || message, type: ECoreBannerTheme.Danger })
    }

    useAuthStore().resetStores()
    await router.push({ name: 'login' })
  } else {
    const code = error.response.status
    let errors = []

    if (error.config.responseType === 'blob') {
      errors = JSON.parse(await error.response.data.text())
    } else if (Array.isArray(error.response.data)) {
      errors = getFlatErrorsArray(error.response.data)
    } else {
      errors = error.response.data
    }

    let message = i18n.global.t('error.default')

    switch (code) {
      case 422:
      case 404:
      case 429:
      case 403:
        if (errors) {
          message = Array.isArray(errors) ? errors[0].message : errors.message
        } else {
          message = i18n.global.t(`error.${code}`)
        }
        break
      case 400:
        if (errors) {
          message = Array.isArray(errors) ? errors[0].message : errors.message
        }
        break
    }

    if (
      code !== 302 &&
      error.response.status &&
      !error.config.doNotShowBanner
    ) {
      appStore.showBannerError(message)
    }
  }

  NProgressInstance.done()
  return Promise.reject(error)
})

export default axiosClient
