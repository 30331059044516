<template>
  <core-modal active
              :title="t(`general.confirm_request`)"
              :closable-button="false">
    <template #body>
      <p class="pre-line">
        {{ overwriteRequestLabel }}
      </p>
    </template>
    <template #footer>
      <core-button :theme="ECoreButtonTheme.GrayHigh"
                   :appearance="ECoreButtonAppearance.Outlined"
                   :text="t('action.cancel')"
                   @click="closeModal" />
      <core-button :text="t(`action.confirm`)"
                   @click="onSubmit" />
    </template>
  </core-modal>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreButton,
  CoreModal,
  ECoreButtonAppearance,
  ECoreButtonTheme
} from '@common/core-ui'

import bus from '@/helpers/eventBus'
import { useAppStore } from '@/stores/app'
import { EBankValidationRequestType } from '@/types/bank-validation-request.d'
import { EBankValidationRequestEvent } from '@/types/eventBus.d'

const { t } = useI18n()

const props = defineProps<{
  requestType: EBankValidationRequestType
}>()

const appStore = useAppStore()

const overwriteRequestLabel = computed(() => {
  return t(`modal.overwrite_request.${props.requestType}`)
})

function closeModal () {
  appStore.closeModal()
}

async function onSubmit () {
  bus.emit(EBankValidationRequestEvent.Overwrite)
  appStore.closeModal()
}
</script>
