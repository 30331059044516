<template>
  <div class="account-information">
    <core-alert v-if="hasTransactionAmountTemporaryActive"
                :data-cy="'account-detail.information.temporary-limits-alert'"
                :icon-name="ECoreIcon.BoInfo"
                :theme="ECoreAlertTheme.Info"
                :text="t('alert.transaction_amount_temporary_active')">
      <template #actions>
        <router-link class="core-link--theme-info core-link--underline core-link--bold"
                     data-cy="card-detail.information.bvr.action"
                     :to="{ name: 'account-settings-temporary-limits' }">
          {{ t('action.manage_transaction_amount_temporary') }}
        </router-link>
      </template>
    </core-alert>
    <div class="account-information__blocks">
      <block-information :account-details="accountDetails" />
      <block-limits :account-details="accountDetails"
                    :has-temporary-limit="hasTransactionAmountTemporaryActive" />
    </div>
    <!-- <block-pricing :account-details="accountDetails" /> -->
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreAlert, ECoreAlertTheme, ECoreIcon } from '@common/core-ui'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

import hasPermission from '@/helpers/permissions'
import { IAccountDetails } from '@/types/account.d'
import { EPermission } from '@/types/permission.d'

import BlockInformation from '@/pages/accounts/account-id/tabs/information/blocks/BlockInformation.vue'
import BlockLimits from '@/pages/accounts/account-id/tabs/information/blocks/BlockLimits.vue'
// import BlockPricing from '@/pages/accounts/account-id/tabs/information/blocks/BlockPricing.vue'

const { t } = useI18n()
dayjs.extend(isBetween)

const props = defineProps<{
  accountDetails: IAccountDetails
}>()

const hasTransactionAmountTemporaryActive = computed(() => {
  if (!hasPermission(EPermission.AccountTransactionAmountTemporaryRead, props.accountDetails)) return false
  const temporaryLimits = props.accountDetails.settings?.transaction_amount_temporary
  return temporaryLimits && temporaryLimits.enabled ? dayjs().isBetween(temporaryLimits.start_date, temporaryLimits.end_date) : false
})
</script>
